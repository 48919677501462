export function hex2rgb(hex: string, opacity?: number): string {
    let h = hex.replace('#', '')
    const matchResult = h.match(new RegExp(`(.{${h.length / 3}})`, 'g')) as string[]

    if (matchResult !== null) {
        h = matchResult.join(',')
    }

    const hArr = h.split(',')

    for (let i = 0; i < hArr.length; i++) {
        hArr[i] = parseInt(hArr[i].length === 1 ? hArr[i] + hArr[i] : hArr[i], 16).toString()
    }

    if (typeof opacity !== 'undefined') {
        hArr.push(opacity.toString())
    }

    return `rgba(${hArr.join(',')})`
}

export function getRandomColor(): string {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

export const gradient_overlay = 'linear-gradient(134.14deg, rgba(255, 220, 0, 0.25) 18.05%, rgba(255, 52, 66, 0.25) 85.85%)'

export const white = '#FFFFFF'

// MASTER COLORS
export const body_color = '#3f4254'
export const red_sweeply = '#FF3442'
export const teal_sweeply = '#42cdc9'
export const yellow_sweeply = '#F9D730'
export const gray_sweeply = '#D9D9D9'

export const blue_sweeply = '#8DD0E4'
export const milk_gray = '#E7E7E7'
export const milk_white = '#FBFBFB'

export const light_black = '#454647'
export const black_sweeply = '#211E1C'
export const main_yellow = '#FFDC2E'
export const main_red = '#FF4C4C'

export const red_spectaflow = '#F85F57'
export const blue_spectaflow = '#53B2D4'
export const green_spectaflow = '#42CDC9'
export const purple_spectaflow = '#B269C8'
export const yello_spectaflow = '#F8BD00'

export const pink_spectaflow = '#e87ea1'
export const dark_grey_spectaflow = '#7D7D7D'
export const light_grey_spectaflow = '#F5F5F5'
export const gentle_gray_spectaflow = '#F4F5F6'

export const light_blue_spectaflow = '#e8f5f9'
export const medium_blue = '#8DD0E4'

export const dark_gray_spectaflow = dark_grey_spectaflow
export const light_gray_spectaflow = light_grey_spectaflow

export const medium_grey_spectaflow = 'rgba(66,66,66,.5)'
export const medium_gray_spectaflow = medium_grey_spectaflow

export const orange_godopronto = '#E98C28'

export const mainBackground = white
// export const mainBackground = '#F5F5F5'
// export const mainBackground = '#e8e8e8'
// export const mainBackground =  light_grey_spectaflow
export const main = mainBackground

// SPACES
export const bgrnd = '#F85F57'
export const home = yello_spectaflow
export const capture = red_spectaflow
export const discover = green_spectaflow
export const importGallery = red_spectaflow

// FORMS
export const textInputBorder = '#E5E5E5'
export const button_gray = textInputBorder

// TEXT
export const textPrimary = '#000000'
export const textGraySecondary = '#616161'
export const textSecondary = dark_gray_spectaflow
export const textThird = medium_grey_spectaflow
export const textWhite = white
export const itemTextBackground = 'rgba(0,0,0,0.5)'

// NAV BARS & BUBBLE
export const nonActiveColor = 'rgba(0,0,0,0.05)'
export const iconBackground = nonActiveColor
export const iconGrey = 'rgba(0,0,0,.33)'

export const bubbleBackground = 'rgba(0,0,0,0.3)'

// BUTTONS
export const greyButton = '#E0E0E0'
export const imageButtonBackground = 'rgba(0,0,0,0.25)'
export const buttonBackground = '#F5F5F5'

// OTHER HELPERS
export const infobox = '#E0E0E0'
export const red = 'rgb(253,64,0)'

export const red2 = 'rgb(248,96,87)'
export const gray = 'rgb(105,105,105)'
export const grey = gray

export const grey_separator = 'rgba(1,1,1,.1)'

// IOS colors
export const ios_grey_font = '#8A8A8F'
export const searchBarFill = '#F1F2F4'
export const searchBarFontColor = '#8E8C96'
export const ios_header_background = white

// Sapiens
export const sapiens_red = '#EF4D23'
export const sapiens_light_brown = '#826E4E'
export const sapiens_burgundy = '#B4352D'
export const sapien_black = '#1F1A1C'
export const sapiens_dark_brown = '#6C5144'

export const webNavbarInactive = '#acacac'

export const curry_yellow_inspection = '#d19700'

export const rose_unclean = '#E87EA1'

export const shadeColor = (color: string, percent: number): string => {
    let R = parseInt(color.substring(1, 3), 16)
    let G = parseInt(color.substring(3, 5), 16)
    let B = parseInt(color.substring(5, 7), 16)

    R = (R * (100 + percent)) / 100
    G = (G * (100 + percent)) / 100
    B = (B * (100 + percent)) / 100

    R = R < 255 ? R : 255
    G = G < 255 ? G : 255
    B = B < 255 ? B : 255

    const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16)
    const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16)
    const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16)

    return '#' + RR + GG + BB
}

export const bs_gray_100 = '#f3f6f9'
export const bs_gray_200 = '#ebedf3'
export const bs_gray_300 = '#e4e6ef'
export const bs_gray_400 = '#d1d3e0'
export const bs_gray_500 = '#b5b5c3'
export const bs_gray_600 = '#7e8299'
export const bs_gray_700 = '#5e6278'
export const bs_gray_800 = '#3f4254'
export const bs_gray_900 = '#181c32'
export const bs_danger = '#f64e60'
export const bs_primary = '#3699ff'
export const bs_success = '#1bc5bd'

export const SnapColors = {
    silver: '#C0C0C4',
    gray: '#211E1C',
    lightGray: bs_gray_300,
    rose: '#FFC9C9',
    red: '#FF4C4C',
    yellow: '#FFDC2E',
    teal: '#8DD0E4',
    lightSilver: '#EAEAEA',
    lightBlue: '#3ABCE3',
    black: '#211E1C',
    white: '#FFFFFF',
    transparentBlack: 'rgba(33, 30, 28, 20)',
    midGray: '#8E8E93'
}
