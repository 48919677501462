export const htmlToMarkdown = (html: string) => {
    // Replace <strong>, <b>, <em>, and <i> tags with respective Markdown syntax
    html = html.replace(/<(strong|b)>(.*?)<\/(strong|b)>/g, '**$2**')
    html = html.replace(/<(em|i)>(.*?)<\/(em|i)>/g, '_$2_')

    // Remove <div> tags but preserve their contents and replace closing tag with Markdown newline
    html = html.replace(/<div[^>]*>/g, '<br>')

    // Remove any remaining HTML tags
    html = html.replace(/<(?!br\s*\/?>)[^>]*>/g, '')

    // Remove trailing <br> tags
    html = html.replace(/<br\s*\/?>\s*$/g, '')

    return html
}

export const markdownToHtml = (markdown?: string) => {
    if (!markdown) return ''
    // Replace bold syntax (double asterisks) with <strong> tags
    markdown = markdown.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')

    // Replace italic syntax (single underscore) with <em> tags
    markdown = markdown.replace(/_(.*?)_/g, '<em>$1</em>')

    markdown = markdown.replace(
        /[&<>'"]/g,
        tag =>
            ({
                '&': '&#38;',
                '<': '&#60;',
                '>': '&#62;',
                "'": '&#39;',
                '"': '&#34;'
            }[tag] || tag)
    )
    markdown = markdown.replace(/\n/g, '<br>')

    return markdown
}

export const markdownToString = (markdown: string) => {
    // Replace Markdown line breaks (two or more spaces at the end of a line) with newline character
    let stringWithNewlines = markdown.replace(/ {2,}\n/g, '\n')

    // Replace Markdown paragraphs (one or more empty lines) with double newline characters
    stringWithNewlines = stringWithNewlines.replace(/\n{2,}/g, '\n\n')

    // Return the resulting string with new lines preserved
    return stringWithNewlines
}

export const htmlToString = (html: string) => {
    html = html.replace(/<.*?>/g, '')

    // Return the resulting string with new lines preserved
    return html
}
