import { TASK_TYPE_ISSUE } from './txt-constants'
import * as helpers from './helpers'
import * as taskData from './task-data'
import { TaskUpdate, completeTask, setTaskUpdate } from './task-data'
import * as dataObjects from './dataObjects'
import { AreaStruct, CommentivityStruct, CurrentUser, IssueStruct, UserStruct } from './dataObjects'
import moment from 'moment-timezone'
import { getArea } from './area-data'
import { IssueArea, IssueItem, TaskStruct } from './firestore-structs'
import { batchCommit, issueItemsSnapshotListener, issuesDocSnapshotListener, issuesSnapshotListener } from './data-helpers'
import { increaseIssueCounter } from './user-data'
import { DocumentReference, Firebase, FirebaseFirestore, Query, WriteBatch } from './firebase'
import * as c from './constants'
import { Image, downloadListOfImages, formatImagesToUpload, uploadListOfImages } from './files-storage-data'
import { Needed } from './type-utils'

/* global Blob */

export interface IssueCreateParams {
    area: AreaStruct
    currentUser: UserStruct
    name: string
    hashtags?: string[]
    dueDate?: number | null
    assignedTo?: Needed<Partial<UserStruct>, 'key'>[] | null
    priority?: boolean
    images?: Blob[] | string[]
}

interface ConstructIssueAssigningFields {
    assignedTo: string | null
    assignedContacts: Pick<UserStruct, 'key' | 'name' | 'initials'>[] | null
    dueDate: number | null
    status?: IssueStruct['status']
}

export type IssueRef = DocumentReference<Omit<IssueStruct, 'commentivities'>>

export interface IssueUpdateRequiredParams {
    issueKey: string
    currentUser: UserStruct
}

export interface IssuesQueryFilters {
    statuses?: IssueStruct['status'][]
    areaKey?: string | null
}

export function getGenericIssuesQuery(firebase: Firebase | FirebaseFirestore, organizationKey: string) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return db.collection('issues').where('organizationKey', '==', organizationKey).orderBy('updated', 'desc') as Query<IssueStruct>
}

export function getFilteredIssuesQuery(
    firebase: Firebase | FirebaseFirestore,
    organizationKey: string,
    { statuses, areaKey }: IssuesQueryFilters
) {
    let query = getGenericIssuesQuery(firebase, organizationKey)

    if (areaKey) {
        query = query.where('areaKey', '==', areaKey)
    }

    if (statuses && statuses.length > 0) {
        query = query.where('status', 'in', statuses)
    }

    return query as Query<IssueStruct>
}

export function getIssueQuery(firebase: Firebase | FirebaseFirestore, issueKey: string) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return db.collection('issues').doc(issueKey) as DocumentReference<IssueStruct>
}

export function getIssuesImageItemsQuery(firebase: Firebase, issueKey: string) {
    return firebase.firestore().collection('issues').doc(issueKey).collection('items').orderBy('updated', 'asc') as Query<IssueItem>
}

export function onFilteredIssues(
    firebase: Firebase,
    organizationKey: string,
    issueStatuses?: IssueStruct['status'][],
    areaKey: string | null = null
) {
    return issuesSnapshotListener(f => getFilteredIssuesQuery(f, organizationKey, { statuses: issueStatuses, areaKey }))(firebase)
}

export function onIssue(firebase: Firebase, issueKey: string) {
    return issuesDocSnapshotListener(f => getIssueQuery(f, issueKey))(firebase)
}

export function onIssueImages(firebase: Firebase, issueKey: string) {
    return issueItemsSnapshotListener(f => getIssuesImageItemsQuery(f, issueKey))(firebase)
}

export async function getIssue(firebase: Firebase, key: string) {
    try {
        const snapIssue = await firebase.firestore().collection('issues').doc(key).get()
        const issue = snapIssue.data() as IssueStruct
        if (issue) {
            issue.key = snapIssue.id
        }
        return issue
    } catch (error) {
        console.log('(IssueData getIssue) ', error)
        return null
    }
}

export function getCommentivitiesQuery(firebase: Firebase | FirebaseFirestore, issueKey: string) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return db.collection('issues').doc(issueKey).collection<CommentivityStruct>('commentivities').orderBy('created', 'desc')
}

export async function getCommentivities(firebase: Firebase, issueKey: string) {
    const commentivitiesSnap = await firebase
        .firestore()
        .collection('issues')
        .doc(issueKey)
        .collection('commentivities')
        .orderBy('created', 'desc')
        .get()
    const commentivities: { [key: string]: CommentivityStruct } = {}
    commentivitiesSnap.forEach(commentivitySnap => {
        const commentivity = commentivitySnap.data() as CommentivityStruct
        if (commentivity && (commentivity.visible === undefined || commentivity.visible)) {
            commentivities[commentivity.key] = commentivity
        }
    })
    return commentivities
}

export async function updateImageItemText(
    firebase: Firebase,
    issueKey: string,
    itemKey: string,
    obj: { content: string },
    currentUser: UserStruct,
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueRef = getIssueQuery(firebase, issueKey)
    const issueUpdate = { lastItemKey: itemKey }

    const textObj = { text: obj }

    batch.update(issueRef.collection('items').doc(itemKey), textObj)
    updateIssue(firebase, { issueKey, currentUser }, issueUpdate, 'changed text on photo to ' + obj.content, batch)

    !externalBatch && (await batchCommit(batch, 'issue-data updateImageItemText', 'updating image item text'))
}

export function constructIssueNumber(initials: string, issueCounter: number) {
    return initials + '#' + issueCounter
}

export function constructIssueNameWithHashtags(name: string, hashtags: string[]) {
    return hashtags.length > 0 ? name + ' ' + helpers.hashTagsToString(hashtags) : name
}

export function constructIssueCreatedCommentivity(
    issueRef: IssueRef,
    currentUser: UserStruct
): { commentivitiesRef: DocumentReference<CommentivityStruct>; commentivityObject: CommentivityStruct } {
    const commentivitiesRef = issueRef.collection('commentivities').doc() as DocumentReference<CommentivityStruct>
    const commentivityObject = dataObjects.getCommentivityObject<'action'>(
        commentivitiesRef.id,
        'action',
        'created the issue',
        true,
        currentUser
    )

    return { commentivitiesRef, commentivityObject }
}

export function constructIssueAssigningFields(
    currentUser: UserStruct,
    assignedTo?: Needed<Partial<UserStruct>, 'key'>[] | null,
    dueDate?: number | null
): ConstructIssueAssigningFields {
    let assignedUsers = assignedTo || null

    if (dueDate && !assignedTo) {
        assignedUsers = [dataObjects.getMiniUserObject(currentUser)]
    }

    const assignedToStr = assignedUsers?.map(user => user.name).join(', ') || null
    const isAssigned = !!assignedToStr

    return {
        assignedTo: assignedToStr ?? null,
        assignedContacts: assignedUsers ?? null,
        dueDate: isAssigned ? dueDate ?? moment().startOf('day').valueOf() : null,
        status: isAssigned ? c.TASK_ASSIGNED : c.TASK_OPEN
    } as ConstructIssueAssigningFields
}

export function constructBaseIssue(
    firebase: Firebase,
    { key, area, currentUser, name, dueDate, priority, hashtags, assignedTo }: IssueCreateParams & { key: string }
): IssueStruct {
    const timestamp = moment().valueOf()
    const userIssueCounter = increaseIssueCounter(firebase, currentUser)

    const issue = {
        key,
        name: constructIssueNameWithHashtags(name, hashtags ?? []),
        area: dataObjects.getAreaObjectForIssue(area),
        created: timestamp,
        organizationKey: currentUser.organizationKey,
        areaKey: area.key,
        creator: currentUser.key,
        issueNumber: constructIssueNumber(currentUser.initials, userIssueCounter),
        lastToucherName: currentUser.name,
        lastToucherKey: currentUser.key,
        isImagesLoading: false,
        updated: timestamp,
        creatorName: currentUser.name,
        type: c.ISSUE_MAINTENANCE as IssueStruct['type'], //Why this is hardcoded in firestore-structs?
        priority: priority ?? false,
        status: c.TASK_OPEN as IssueStruct['status'],
        visible: true,
        lastItemKey: c.TEXT_ISSUE,
        taskKey: null,
        items: [],
        item: null,
        lastThumbUrl: null,
        commentivities: [],
        updates: {
            [currentUser.key]: timestamp
        }
    }

    return { ...issue, ...constructIssueAssigningFields(currentUser, assignedTo, dueDate) }
}

export function constructIssueImageItem(issueKey: string, organizationKey: string, image: Image) {
    const { key, url, thumbUrl, name } = image
    const timestamp = moment().valueOf()
    const textContent = new Date(timestamp).toLocaleTimeString('en-GB', {
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric'
    })
    const IMAGE_EXTENSION = '.jpg'
    const POSITION = 335.5

    return {
        issueKey,
        key,
        created: timestamp,
        url,
        fileName: name,
        thumbUrl: thumbUrl,
        mediaType: 'file',
        thumbExtension: IMAGE_EXTENSION,
        organizationKey,
        text: {
            content: textContent,
            position: POSITION
        },
        updated: timestamp,
        visible: true
    } as IssueItem
}

export function constructItemsUpdatedFields(lastItem: IssueItem, currentUser: UserStruct) {
    const timeStamp = moment().valueOf()

    return {
        lastThumbUrl: lastItem.thumbUrl,
        lastItemKey: lastItem.key,
        updated: timeStamp,
        lastToucherName: currentUser.name,
        lastToucherKey: currentUser.key,
        isImagesLoading: false
    } as Pick<IssueStruct, 'lastItemKey' | 'updated' | 'lastToucherKey' | 'lastToucherName' | 'isImagesLoading' | 'lastThumbUrl'>
}

export async function createIssueImageItems(
    firebase: Firebase,
    params: { currentUser: UserStruct; issueKey: string },
    images: Blob[] | string[]
) {
    const isImagesAlreadyCompressed = images.some(image => typeof image === 'string') && images.length % 2 === 0
    const initialImages = isImagesAlreadyCompressed ? images.slice(0, images.length / 2) : images
    const initialCompressedImages = isImagesAlreadyCompressed ? images.slice(images.length / 2) : images
    const formattedImages = await formatImagesToUpload(initialImages, { currentUserKey: params.currentUser.key, extension: '.jpg' })
    const formattedCompressedImages = await formatImagesToUpload(
        initialCompressedImages,
        { currentUserKey: params.currentUser.key, extension: '_thumb.jpg' },
        true
    )

    const imagesMetadata = await uploadListOfImages(firebase, formattedImages)
    const compressedImagesMetadata = await uploadListOfImages(firebase, formattedCompressedImages)

    const allImagesUrls = await downloadListOfImages(firebase, [...imagesMetadata, ...compressedImagesMetadata])
    const compressedImagesUrls = allImagesUrls.slice(imagesMetadata.length)
    const imagesUrls = allImagesUrls.slice(0, imagesMetadata.length)

    const imageItems = imagesUrls.map((url, index) => {
        const metadata = imagesMetadata[index]
        const thumbUrl = compressedImagesUrls[index]
        const itemRef = firebase.firestore().collection('issues').doc(params.issueKey).collection('items').doc()

        const image = {
            ...metadata,
            thumbUrl,
            url,
            key: itemRef.id
        }

        const imageItem = constructIssueImageItem(params.issueKey, params.currentUser.organizationKey, image)

        return { itemRef, imageItem }
    })

    return imageItems
}

export async function assignImageItemsToIssue(
    firebase: Firebase,
    params: { currentUser: UserStruct; issueKey: string },
    images: Blob[] | string[],
    batch?: WriteBatch
) {
    try {
        const items = await createIssueImageItems(firebase, params, images)

        items.forEach(async ({ itemRef, imageItem }) => {
            batch ? batch.set(itemRef, imageItem) : await itemRef.set(imageItem)
        })

        return items
    } catch (error: unknown) {
        console.error('(IssueData assignImageItemsToIssue) ', error)
        throw new Error('Error while assigning images to issue, please try again')
    }
}

export async function createIssue(firebase: Firebase, params: IssueCreateParams): Promise<IssueStruct> {
    const db = firebase.firestore()
    const batch = db.batch()
    const issueRef = db.collection('issues').doc() as IssueRef

    let issue = constructBaseIssue(firebase, { key: issueRef.id, ...params })
    const commentivity = constructIssueCreatedCommentivity(issueRef, params.currentUser)

    if (issue.status === c.TASK_ASSIGNED) {
        const taskConfigs = {
            issue,
            assignedTo: issue.assignedContacts,
            startDate: issue.dueDate ?? moment().startOf('day').valueOf()
        }
        const task = await taskData.createTask<typeof TASK_TYPE_ISSUE>(
            firebase,
            params.area,
            params.currentUser,
            TASK_TYPE_ISSUE,
            taskConfigs,
            batch
        )

        issue.taskKey = task.key
    }

    if (params.images && params.images.length > 0) {
        const items = await assignImageItemsToIssue(
            firebase,
            { currentUser: params.currentUser, issueKey: issueRef.id },
            params.images,
            batch
        )

        issue = {
            ...issue,
            ...constructItemsUpdatedFields(items[items.length - 1].imageItem, params.currentUser),
            items: items.map(i => i.imageItem)
        }
    }

    batch.set(issueRef, issue)
    batch.set(commentivity.commentivitiesRef, commentivity.commentivityObject)

    await batch.commit().catch(error => {
        console.error('(IssueData createIssue) ', error)
        throw new Error('Error while creating issue, please try again')
    })

    return { ...issue, commentivities: [commentivity.commentivityObject] }
}

export async function touchIssueAfterImageUpload(
    firebase: Firebase,
    issueKey: string,
    items: IssueItem[],
    currentUser: UserStruct,
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? firebase.firestore().batch()
    const issueRef = db.collection('issues').doc(issueKey)

    const issueUpdate = { items, ...constructItemsUpdatedFields(items[items.length - 1], currentUser) }
    batch.update(issueRef, issueUpdate)

    !externalBatch && (await batchCommit(batch, 'issue-data touchIssueAfterImageUpload', 'touching issue after image upload'))
}

export async function toggleIsImagesLoading(firebase: Firebase, issueKey: string, externalBatch?: WriteBatch) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueRef = db.collection('issues').doc(issueKey)

    batch.update(issueRef, { isImagesLoading: true })

    !externalBatch && (await batchCommit(batch, 'issue-data toggleIsImagesLoading', 'toggling isImagesLoading'))
}

export async function updateIssue(
    firebase: Firebase,
    params: IssueUpdateRequiredParams,
    issueUpdate: Partial<IssueStruct>,
    commentivityText: string,
    externalBatch?: WriteBatch
) {
    const { currentUser, issueKey } = params
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueRef = db.collection('issues').doc(issueKey)

    batch.update(issueRef, issueUpdate)
    await addCommentivityToIssue(firebase, issueKey, 'action', commentivityText, currentUser, false, batch)

    !externalBatch && (await batchCommit(batch, 'issue-data updateIssue', 'updating issue'))

    return { key: issueRef.id, ...issueUpdate } as Partial<IssueStruct>
}

export async function touchIssueTask(
    firebase: Firebase,
    params: TaskUpdate & IssueUpdateRequiredParams & { area: IssueArea | AreaStruct },
    taskKey?: string | null,
    externalBatch?: WriteBatch
) {
    let task: Partial<TaskStruct>
    const { area, currentUser, issueKey, startDate, ...rest } = params

    if (taskKey) {
        task = await taskData.setTaskUpdate(firebase, currentUser, taskKey, { ...rest, startDate }, externalBatch)
    } else {
        const issue = (await getIssue(firebase, issueKey)) as IssueStruct
        task = await taskData.createTask<typeof TASK_TYPE_ISSUE>(
            firebase,
            area,
            currentUser,
            TASK_TYPE_ISSUE,
            {
                startDate,
                issue,
                assignedTo: rest?.assignedTo ? rest.assignedTo.map(u => dataObjects.getMiniUserObject(u)) : issue.assignedContacts
            },
            externalBatch
        )
    }

    return task
}

export async function setPriorityToIssue(
    firebase: Firebase,
    { issueKey, priority, currentUser }: IssueUpdateRequiredParams & { priority: boolean },
    externalBatch?: WriteBatch
) {
    const commentivityText = 'changed priority to ' + (priority ? 'high' : 'low')
    await updateIssue(firebase, { issueKey, currentUser }, { priority }, commentivityText, externalBatch)
    return priority
}

export async function assignIssue(
    firebase: Firebase,
    params: IssueUpdateRequiredParams & {
        area: IssueArea | AreaStruct
        assignedTo: Needed<Partial<UserStruct>, 'key' | 'name' | 'initials'>[] | null
        dueDate?: number | null
        taskKey?: string | null
    },
    externalBatch?: WriteBatch
) {
    const { area, issueKey, currentUser, assignedTo, dueDate, taskKey } = params
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueChangedFields = constructIssueAssigningFields(currentUser, assignedTo, dueDate)

    const task = await touchIssueTask(firebase, { area, currentUser, issueKey, assignedTo, startDate: dueDate }, taskKey, batch)
    const issue: Partial<IssueStruct> = { ...issueChangedFields, key: issueKey, taskKey: task.key }
    await updateIssue(firebase, { issueKey, currentUser }, issue, 'assigned the issue to ' + issue.assignedTo, batch)

    !externalBatch && (await batchCommit(batch, 'issue-data assignIssue', 'assigning issue'))

    return { issue, task }
}

export async function setDueDateToIssue(
    firebase: Firebase,
    {
        area,
        issueKey,
        currentUser,
        dueDate,
        taskKey,
        assignedTo
    }: IssueUpdateRequiredParams & {
        area: IssueArea | AreaStruct
        dueDate: number | null
        assignedTo: Needed<Partial<UserStruct>, 'key' | 'name' | 'initials'>[] | null
        taskKey?: string | null
    },
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const dueDateText = dueDate ? `changed due date to ${moment(dueDate).format('DD/MM/YYYY')}` : `removed due date`
    const issueAssigningField = constructIssueAssigningFields(currentUser, assignedTo, dueDate)
    issueAssigningField.dueDate = dueDate === null ? null : issueAssigningField.dueDate

    const task = await touchIssueTask(
        firebase,
        { area, currentUser, issueKey, assignedTo: issueAssigningField?.assignedContacts ?? assignedTo, startDate: dueDate },
        taskKey,
        batch
    )
    const issue = await updateIssue(firebase, { issueKey, currentUser }, issueAssigningField, dueDateText, batch)

    !externalBatch && (await batchCommit(batch, 'issue-data setDueDateToIssue', 'setting due date to issue'))

    return { issue, task }
}

export async function completeIssue(
    firebase: Firebase,
    params: IssueUpdateRequiredParams & { taskKey?: string | null },
    externalBatch?: WriteBatch
) {
    const { issueKey, currentUser, taskKey } = params
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueUpdate = { status: c.ISSUE_COMPLETE as IssueStruct['status'] }

    const issue = await updateIssue(firebase, { issueKey, currentUser }, issueUpdate, 'completed the issue', batch)
    taskKey && (await completeTask(firebase, currentUser, taskKey, batch))

    !externalBatch && (await batchCommit(batch, 'issue-data completeIssue', 'completing issue'))

    return issue
}

export async function uncompleteIssue(
    firebase: Firebase,
    params: IssueUpdateRequiredParams & {
        assignedContacts: IssueStruct['assignedContacts'] | TaskStruct['assignedTo']
        taskKey?: string | null
    },
    externalBatch?: WriteBatch
) {
    const { issueKey, currentUser, assignedContacts, taskKey } = params
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueUpdate: Partial<IssueStruct> = { status: assignedContacts?.length ? c.ISSUE_ASSIGNED : c.ISSUE_OPEN }

    const issue = await updateIssue(firebase, { issueKey, currentUser }, issueUpdate, 'uncompleted the issue', batch)
    taskKey && setTaskUpdate(firebase, currentUser, taskKey, { status: issueUpdate.status }, batch)

    !externalBatch && (await batchCommit(batch, 'issue-data uncompleteIssue', `set status to ${issueUpdate.status}`))

    return issue
}

export async function deleteIssue(firebase: Firebase, { issueKey, currentUser }: IssueUpdateRequiredParams, externalBatch?: WriteBatch) {
    return await updateIssue(firebase, { issueKey, currentUser }, { status: c.ISSUE_DELETED }, 'deleted the issue', externalBatch)
}

export async function removeDueDateFromIssue(
    firebase: Firebase,
    params: IssueUpdateRequiredParams & {
        area: IssueArea | AreaStruct
        assignedTo: Needed<Partial<UserStruct>, 'key' | 'name' | 'initials'>[] | null
        taskKey?: string | null
    },
    externalBatch?: WriteBatch
) {
    return await setDueDateToIssue(firebase, { ...params, dueDate: null }, externalBatch)
}

export async function changeIssueName(
    firebase: Firebase,
    { issueKey, currentUser, name, taskKey }: IssueUpdateRequiredParams & { name: string; taskKey?: string | null },
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const commentivityText = 'changed the name to ' + `"${name}"`

    let task: Partial<TaskStruct> | null = null

    const issue = await updateIssue(firebase, { issueKey, currentUser }, { name }, commentivityText, batch)

    if (taskKey) {
        task = await taskData.setTaskUpdate(firebase, currentUser, taskKey, { name }, batch)
    }

    !externalBatch && (await batchCommit(batch, 'issue-data changeIssueName', 'changing issue name'))

    return { issue, task }
}

export async function unassignIssue(
    firebase: Firebase,
    params: IssueUpdateRequiredParams & { taskKey: string },
    externalBatch?: WriteBatch
) {
    const { issueKey, currentUser, taskKey } = params
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueUpdate = constructIssueAssigningFields(currentUser, null) as Partial<IssueStruct>
    delete issueUpdate.dueDate

    const issue = await updateIssue(firebase, { issueKey, currentUser }, issueUpdate, 'unassigned the issue', batch)
    await taskData.unassignTask(firebase, currentUser, taskKey, batch)

    !externalBatch && (await batchCommit(batch, 'issue-data unassignIssue', 'unassigning issue'))

    return issue
}

export async function changeArea(
    firebase: Firebase,
    {
        currentUser,
        issueKey,
        areaKey,
        taskKey = null
    }: { currentUser: CurrentUser; issueKey: string; areaKey: string; taskKey?: string | null }
) {
    const db = firebase.firestore()
    const batch = db.batch()
    const issueRef = db.collection('issues').doc(issueKey)
    const area = await getArea(firebase, areaKey)

    const areaObject = {
        key: area.key,
        name: area.name,
        description: area.description,
        address: area.address,
        group: area.group
    }

    const issueObject = {
        area: areaObject,
        areaKey: areaKey,
        updated: moment().valueOf(),
        lastToucherName: currentUser.name,
        lastToucherKey: currentUser.key
    }

    if (taskKey) {
        taskData.setTaskUpdate(firebase, currentUser, taskKey, { area }, batch)
    }

    batch.update(issueRef, issueObject)

    const commentivitiesRef = issueRef.collection('commentivities').doc()
    const commentivityObject = dataObjects.getCommentivityObject(commentivitiesRef.id, 'action', 'changed the area', false, currentUser)
    batch.set(commentivitiesRef, commentivityObject)

    await batch.commit().catch(error => {
        console.error('(IssueData changeArea) ', error)
    })

    return issueObject
}

export async function addCommentivityToIssue(
    firebase: Firebase,
    issueKey: string,
    type: CommentivityStruct['type'],
    text: string,
    currentUser: UserStruct,
    notifyCreator = true,
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()

    const issueRef = db.collection('issues').doc(issueKey)
    const commentivitiesRef = issueRef.collection('commentivities').doc()

    const commentivityObject = dataObjects.getCommentivityObject(commentivitiesRef.id, type, text, notifyCreator, currentUser)

    batch.set(commentivitiesRef, commentivityObject)
    batch.update(issueRef, dataObjects.touchIssueObject(currentUser))

    !externalBatch &&
        (await batch.commit().catch(error => {
            console.log('(IssueData addCommentivityToIssue) ', error)
        }))

    return commentivityObject.key
}

export async function getIssueItems(firebase: Firebase, issueKey: string) {
    const itemsSnap = await firebase.firestore().collection('issues').doc(issueKey).collection('items').orderBy('updated', 'asc').get()
    const items: IssueItem[] = []
    itemsSnap.forEach(itemSnap => {
        const item = itemSnap.data() as IssueItem
        if (item && (item.visible === undefined || item.visible)) {
            items.push(item)
        }
    })
    return items
}

export async function returnIssueUpdatedFieldsAfterItemDeleted(
    firebase: Firebase,
    issueKey: string,
    itemKey: string,
    isLastItem: boolean
): Promise<Pick<IssueStruct, 'lastItemKey' | 'lastThumbUrl'> | void> {
    if (isLastItem) {
        return { lastItemKey: 'text-issue', lastThumbUrl: null }
    } else {
        const items = (await getIssueItems(firebase, issueKey))
            .filter(item => item.key !== itemKey)
            .sort((a, b) => helpers.sortTimeStampDescending(a.updated, b.updated))

        if (items.length > 0) {
            return { lastItemKey: items[0].key, lastThumbUrl: items[0].url }
        }
    }
}

export async function deleteItemFromIssue(
    firebase: Firebase,
    { currentUser, issueKey, itemKey, isLastItem }: IssueUpdateRequiredParams & { itemKey: string; isLastItem: boolean },
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueRef = db.collection('issues').doc(issueKey)
    const itemRef = issueRef.collection('items').doc(itemKey)

    batch.update(itemRef, { visible: false })

    addCommentivityToIssue(firebase, issueKey, 'action', 'removed one photo', currentUser, false, batch)
    const issueUpdate = await returnIssueUpdatedFieldsAfterItemDeleted(firebase, issueKey, itemKey, isLastItem)

    issueUpdate && batch.update(issueRef, issueUpdate)

    !externalBatch && (await batchCommit(batch, 'issue-data removeItemFromIssue', 'removing item from issue'))

    return issueUpdate
}

export async function addImageItemsToIssue(
    firebase: Firebase,
    {
        issueKey,
        currentUser,
        images,
        currentItems
    }: IssueUpdateRequiredParams & { images: Blob[] | string[]; currentItems?: IssueStruct['items'] },
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueRef = db.collection('issues').doc(issueKey)
    const items = await assignImageItemsToIssue(firebase, { currentUser, issueKey }, images, batch)
    const issueUpdate =
        currentItems && currentItems.length > 0
            ? {
                  items: [...items.map(item => item.imageItem), ...currentItems],
                  ...constructItemsUpdatedFields(items[items.length - 1].imageItem, currentUser)
              }
            : constructItemsUpdatedFields(items[items.length - 1].imageItem, currentUser)
    const commentivityText = `added ${items.length} photo${items.length > 1 ? 's' : ''}`

    batch.update(issueRef, issueUpdate)
    addCommentivityToIssue(firebase, issueKey, 'action', commentivityText, currentUser, false, batch)

    !externalBatch && (await batchCommit(batch, 'issue-data addImageItemsToIssue', 'adding image items to issue'))

    return issueUpdate
}

export async function addCommentToIssue(
    firebase: Firebase,
    { issueKey, currentUser, text }: IssueUpdateRequiredParams & { text: string },
    externalBatch?: WriteBatch
) {
    return await addCommentivityToIssue(firebase, issueKey, 'comment', text, currentUser, false, externalBatch)
}

export async function deleteCommentivity(
    firebase: Firebase,
    { issueKey, commentivityKey, currentUser }: IssueUpdateRequiredParams & { commentivityKey: string },
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()

    const issueRef = db.collection('issues').doc(issueKey)
    const commentivitiesRef = issueRef.collection('commentivities').doc(commentivityKey)

    batch.update(commentivitiesRef, { visible: false })
    batch.update(issueRef, dataObjects.touchIssueObject(currentUser))

    !externalBatch && (await batchCommit(batch, 'issue-data deleteCommentivity', 'deleting commentivity'))

    return commentivitiesRef.id
}

export async function addHashtagToIssue(
    firebase: Firebase,
    { issueKey, currentUser, hashtag, currentName }: IssueUpdateRequiredParams & { hashtag: string; currentName: IssueStruct['name'] },
    externalBatch?: WriteBatch
) {
    const updatedName = `${currentName} ${hashtag}`.trim()

    const commentivityText = `added hashtag ${hashtag}`

    await updateIssue(firebase, { issueKey, currentUser }, { name: updatedName }, commentivityText, externalBatch)

    return updatedName
}

export async function removeHashtagFromIssue(
    firebase: Firebase,
    { issueKey, currentUser, hashtag, currentName }: IssueUpdateRequiredParams & { hashtag: string; currentName: IssueStruct['name'] },
    externalBatch?: WriteBatch
) {
    const updatedName = helpers.removeHashtag(currentName, hashtag)

    const commentivityText = `removed hashtag ${hashtag}`

    await updateIssue(firebase, { issueKey, currentUser }, { name: updatedName }, commentivityText, externalBatch)

    return updatedName
}
