import moment, { Moment } from 'moment-timezone'

import type {
    ActivityChangeObject,
    ActivityStruct,
    ActivityType,
    AreaCleaningStatus,
    AreaStruct,
    BookingStruct,
    CleaningStruct,
    CommentivityStruct,
    CurrencyCode,
    DailyCommentStruct,
    IssueArea,
    IssueStruct,
    OrgStruct,
    RuleStruct,
    SubscriptionItemStruct,
    SubscriptionPlan,
    SubscriptionStruct,
    TaskStruct,
    UserRole,
    UserStruct,
    Occupancy
} from './firestore-structs'

export type {
    AreaStruct,
    AreaCleaningStatus,
    IssueStruct,
    UserStruct,
    RuleStruct,
    TaskStruct,
    OrgStruct,
    ActivityStruct,
    CommentivityStruct,
    BookingStruct,
    SubscriptionPlan,
    DailyCommentStruct,
    SubscriptionStruct,
    SubscriptionItemStruct,
    Occupancy
}

export type Admin = {
    name: string
    phoneNumber: string
    organizationKey: string
    areaGroups: string[]
    initials: string
    email: string
    visible: boolean
    brand: 'Godo Pronto' | 'Sweeply'
}

export interface CurrentUser {
    key: string
    initials: string
    name: string
}

export type IssueStatus = 'my' | 'any' | 'open' | 'assigned' | 'completed' | 'deleted'
export type TaskStatus = 'any' | 'open' | 'assigned' | 'completed' | 'deleted'

// export type IssueStructNormalized = IssueStruct & {
//     dueDate?: number
// }

export function getUserObject(
    userKey: string | null,
    userPhonNumber: string,
    userName: string,
    userInitials: string,
    userEmail: string,
    organizationKey: string,
    userAreaGroups: string[],
    userIssueHashtags: string[],
    userAssignIssueHashtags: boolean,
    userShowCleaningTaskNote: boolean,
    userVisible: boolean,
    userRole: UserRole,
    isActive = false
): Partial<UserStruct> {
    return {
        key: userKey ?? undefined,
        phoneNumber: userPhonNumber,
        name: userName,
        initials: userInitials,
        email: userEmail,
        organizationKey: organizationKey,
        areaGroups: Array.isArray(userAreaGroups) ? userAreaGroups : [],
        issueHashtags: Array.isArray(userIssueHashtags) ? userIssueHashtags : [],
        assignIssueHashtags: !!userAssignIssueHashtags,
        showCleaningTaskNote: !!userShowCleaningTaskNote,
        visible: userVisible,
        authRole: userRole,
        isActive
    }
}

export function getMiniUserObject(user: Partial<UserStruct>): Pick<UserStruct, 'key' | 'name' | 'initials'> {
    return {
        key: user.key!,
        initials: user.initials!,
        name: user.name!
    }
}

export function authUserObject(
    userKey: string,
    organizationKey: string,
    organizationName: string,
    authRole: UserRole
): {
    userKey: string
    organizationKey: string
    organizationName: string
    authRole: UserRole
    authSuper?: boolean
} {
    return {
        userKey,
        organizationKey,
        organizationName,
        authRole: authRole
    }
}

export function getSuperAdminObjects(organizationKey: string): Admin[] {
    return [
        {
            name: 'Sweeply Support',
            phoneNumber: '+3549991111',
            organizationKey,
            areaGroups: ['All'],
            initials: 'SPS',
            email: 'support@getsweeply.com',
            visible: false,
            brand: 'Sweeply' as const
        },
        {
            name: 'Erlendur Steinn Guðnason',
            phoneNumber: '+3548609100',
            organizationKey,
            areaGroups: ['All'],
            initials: 'ESG',
            email: 'erlendur@getsweeply.com',
            visible: false,
            brand: 'Sweeply' as const
        },
        {
            name: 'Brynja Þrastardóttir',
            phoneNumber: '+3548400101',
            organizationKey,
            areaGroups: ['All'],
            initials: 'BTH',
            email: 'brynja@getsweeply.com',
            visible: false,
            brand: 'Sweeply' as const
        },
        {
            name: 'Petar Shomov',
            phoneNumber: '+3548242728',
            organizationKey,
            areaGroups: ['All'],
            initials: 'PES',
            email: 'petar@getsweeply.com',
            visible: false,
            brand: 'Sweeply' as const
        },
        {
            name: 'Frans Veigar Garðarsson',
            phoneNumber: '+3548495890',
            organizationKey,
            areaGroups: ['All'],
            initials: 'FVG',
            email: 'frans@getsweeply.com',
            visible: false,
            brand: 'Sweeply' as const
        }
    ]
}

export function getBrandAdminObjects(organizationKey: string): Admin[] {
    return [
        {
            name: 'Pronto Support',
            phoneNumber: '+3545554636',
            organizationKey,
            areaGroups: ['All'],
            initials: 'PS',
            email: 'support@godo.is',
            visible: false,
            brand: 'Godo Pronto' as const
        },
        {
            name: 'Lára Sverrisdóttir',
            phoneNumber: '+3548476756',
            organizationKey,
            areaGroups: ['All'],
            initials: 'LSV',
            email: 'lara@godo.is',
            visible: false,
            brand: 'Godo Pronto' as const
        },
        {
            name: 'Kristín Grímsdóttir',
            phoneNumber: '+3548485198',
            organizationKey,
            areaGroups: ['All'],
            initials: 'KGR',
            email: 'kristin@godo.is',
            visible: false,
            brand: 'Godo Pronto' as const
        },
        {
            name: 'Örvar Steinback',
            phoneNumber: '+3548430608',
            organizationKey,
            areaGroups: ['All'],
            initials: 'ÖST',
            email: 'orvarst@godo.is',
            visible: false,
            brand: 'Godo Pronto' as const
        },
        {
            name: 'Þórdís Erla Sveinsdóttir',
            phoneNumber: '+3546911852',
            organizationKey,
            areaGroups: ['All'],
            initials: 'ÞES',
            email: 'thordis@godo.is',
            visible: false,
            brand: 'Godo Pronto' as const
        },
        {
            name: 'Anna Sigurðardóttir',
            phoneNumber: '+3546112443',
            organizationKey,
            areaGroups: ['All'],
            initials: 'ASI',
            email: 'annasig@godo.is',
            visible: false,
            brand: 'Godo Pronto' as const
        }
    ]
}

export function getOrganizationObject(
    orgKey: string | null,
    orgName: string,
    orgStatus: any,
    issueHashtags: string[] = [],
    assignMaintenance = false,
    excludeMaintenanceNotification: string[] = [],
    currencyCode = 'ISK' as CurrencyCode,
    timezone = 'Atlantic/Reykjavik',
    allowOptIn = false,
    allowOptOut = false
): {
    key?: string
    name: string
    timezone: string
    currencyCode: CurrencyCode
    logo: string
    issueHashtags: string[]
    assignMaintenance: boolean
    excludeMaintenanceNotification: string[]
    type: 'customer'
    modules: {
        web: true
        app: true
    }
    status: any
    allowOptIn: boolean
    allowOptOut: boolean
    updated: number
} {
    const result = {
        name: orgName,
        timezone: timezone,
        currencyCode,
        logo: '',
        issueHashtags,
        assignMaintenance,
        excludeMaintenanceNotification,
        type: 'customer' as const,
        modules: {
            web: true as const,
            app: true as const
        },
        status: orgStatus,
        allowOptIn,
        allowOptOut,
        updated: moment().valueOf()
    }
    return { ...result, key: orgKey! }
}

export function getAreaObject<T extends Partial<AreaStruct>>(
    area: T
): Pick<T, 'key' | 'name' | 'description' | 'address' | 'group' | 'cleaningFrequency'> {
    return {
        key: area.key,
        name: area.name,
        description: area.description || '',
        address: area.address || '',
        group: area.group,
        cleaningFrequency: area.cleaningFrequency ? area.cleaningFrequency : 'unknown'
    }
}

export function getAreaObjectForTask<T extends Partial<AreaStruct>>(
    area: T
): ReturnType<typeof getAreaObject> &
    Pick<T, 'cleaningStatus' | 'occupancy' | 'synced' | 'lastCleaningTaskKey' | 'guestCheckedIn' | 'guestCheckedOut'> {
    const areaObject = getAreaObject(area)

    let result = {}
    if (area.cleaningStatus) {
        result = { ...result, cleaningStatus: area.cleaningStatus }
    }
    if (area.occupancy) {
        result = { ...result, occupancy: area.occupancy }
    }

    if (area.synced) {
        result = { ...result, synced: area.synced }
    }

    if (area.lastCleaningTaskKey) {
        result = { ...result, lastCleaningTaskKey: area.lastCleaningTaskKey }
    }

    if (area.guestCheckedIn) {
        result = { ...result, guestCheckedIn: area.guestCheckedIn }
    }

    if (area.guestCheckedOut) {
        result = { ...result, guestCheckedOut: area.guestCheckedOut }
    }

    return { ...areaObject, ...result }
}

export function getTaskBaseObject<TaskType extends TaskStruct['type']>(
    area: Partial<Omit<AreaStruct, 'activeRule'>> | null,
    startDate: string | number | Moment | undefined | null,
    assignedTo: Pick<UserStruct, 'key' | 'name' | 'initials'>[] | null,
    type: TaskType,
    creator: UserStruct
): Partial<TaskStruct> & { assignedToKeys?: string[] } {
    const taskObject: Partial<TaskStruct> & { assignedToKeys?: string[] } = {
        created: moment().valueOf(),
        type,
        visible: true,
        creator: getMiniUserObject(creator),
        lastModifiedBy: getMiniUserObject(creator),
        organizationKey: creator.organizationKey,
        updated: moment().valueOf()
    }

    taskObject.startDate = startDate === null ? startDate : moment(startDate).startOf('day').valueOf()

    const optionalAssigned = assignedTo
        ? {
              assignedToKeys: assignedTo.map(a => a.key!),
              assignedTo: assignedTo.map(a => {
                  return getMiniUserObject(a)
              })
          }
        : {}

    const optionalArea = area
        ? {
              area: getAreaObjectForTask(area),
              areaKey: area.key,
              ...(area.propKey ? { propKey: area.propKey } : {}),
              ...(area.propName ? { propName: area.propName } : {})
          }
        : {}

    return { ...taskObject, ...optionalAssigned, ...optionalArea }
}

export function touchIssueObject(currentUser: CurrentUser): Partial<IssueStruct> {
    const timeStamp = moment().valueOf()

    const issueObject = {
        updated: timeStamp,
        lastToucherName: currentUser.name,
        lastToucherKey: currentUser.key,
        updates: {
            [currentUser.key]: timeStamp
        }
    }

    return issueObject
}

export function getCommentivityObject<T extends CommentivityStruct['type']>(
    commentivityKey: string,
    type: T,
    text: string,
    notify: boolean,
    currentUser: CurrentUser
): CommentivityStruct {
    const timeStamp = moment().valueOf()
    return {
        key: commentivityKey,
        type,
        created: timeStamp,
        text,
        visible: true,
        creator: {
            key: currentUser.key,
            initials: currentUser.initials,
            name: currentUser.name,
            notify
        }
    }
}

export function getCleaningTaskObject(
    currentUser: UserStruct,
    area: Partial<AreaStruct> | null = null,
    selectedDateNumber: number | null = null,
    assignedTo: Pick<UserStruct, 'key' | 'name' | 'initials'>[] | null = null,
    activeRule: Partial<RuleStruct> | null = null,
    cleaning?: CleaningStruct
): Partial<TaskStruct> {
    const startDate = selectedDateNumber ? selectedDateNumber : moment().valueOf()
    const status = assignedTo ? 'assigned' : 'open'

    const taskObject = getTaskBaseObject(area, startDate, assignedTo, 'cleaning', currentUser)
    if (activeRule && activeRule.checklistTasks) {
        taskObject.checklist = activeRule.checklistTasks.map((item, index) => {
            return {
                name: item,
                checked: false
            }
        })
    }

    return { ...taskObject, cleaning, name: activeRule?.name ?? 'Clean & prepare', status }
}

export function getIssueObjectForTask(
    issue: IssueStruct
): Pick<IssueStruct, 'key' | 'status' | 'issueNumber' | 'priority' | 'name' | 'lastToucherName' | 'updated' | 'created'> {
    const baseFields = {
        key: issue.key,
        status: issue.status,
        issueNumber: issue.issueNumber,
        priority: issue.priority,
        name: issue.name,
        lastToucherName: issue.lastToucherName,
        updated: issue.updated,
        created: issue.created
    }

    const optionalFields: Partial<Pick<IssueStruct, 'lastItemKey' | 'lastThumbUrl'>> = {}

    if (issue.lastItemKey) {
        optionalFields.lastItemKey = issue.lastItemKey
    }

    if (issue.lastThumbUrl) {
        optionalFields.lastThumbUrl = issue.lastThumbUrl
    }

    return {
        ...baseFields,
        ...optionalFields
    }
}
export type ChangeObject = {
    before: string
    after: string
    field: string
}

export interface IActivityObject {
    areaKey: string
    type: string
    date: number
    created: number
    change: ChangeObject
    key: string
    organizationKey: string
    user?: CurrentUser
}

export function getActivityObject(
    key: string,
    areaKey: string,
    type: ActivityType,
    selectedDateNumber: number,
    changeObj: ActivityChangeObject,
    organizationKey: string,
    userMiniObj?: CurrentUser
): ActivityStruct {
    return {
        areaKey: areaKey,
        type: type,
        date: selectedDateNumber,
        created: moment().valueOf(),
        change: changeObj,
        key: key,
        organizationKey: organizationKey,
        user: userMiniObj
    }
}

export enum Features {
    workloadReports = 'workloadReports'
}

export function getAreaObjectForIssue(area: AreaStruct): IssueArea {
    return {
        key: area.key,
        name: area.name,
        description: area.description || '',
        address: area.address ?? '',
        group: area.group || ''
    }
}

export function getCleaningObject(cleaning?: CleaningStruct): CleaningStruct {
    const cleaningObject = {
        end: cleaning?.end,
        lastCleanedBy: cleaning?.lastCleanedBy,
        pause: cleaning?.pause ?? [],
        stop: cleaning?.stop ?? [],
        play: cleaning?.play ?? [],
        start: cleaning?.start ?? moment().valueOf()
    }
    if (!cleaningObject.end) delete cleaningObject.end
    return cleaningObject
}
