import {
    ActivityStruct,
    AreaStruct,
    AreaSummaryProjectionStruct,
    BookingStruct,
    DailyCommentStruct,
    OrgStruct,
    RuleStruct,
    TaskStruct
} from '../firestore-structs'
import { calculateCleaningStatusSync } from '../cleaning-calculator'
import { sortTimeStampDescending } from '../helpers'
import { findLeadBooking } from '../booking-service'

export function mapToAreaProjection({
    area,
    startOfDayInTZ,
    org,
    bookings,
    activities,
    rules,
    currentTask,
    dailyComments,
    lastCleaningTask
}: {
    area: AreaStruct
    startOfDayInTZ: number
    org: OrgStruct
    bookings: BookingStruct[]
    activities: ActivityStruct[]
    rules: RuleStruct[]
    currentTask?: TaskStruct
    dailyComments: DailyCommentStruct[]
    lastCleaningTask: TaskStruct | null
}) {
    const updatedArea = calculateCleaningStatusSync([area], startOfDayInTZ, org, bookings, activities, rules)

    const isPriority: boolean =
        currentTask?.priority ||
        (activities.filter(x => x.type === 'cleaning-priority').sort((a, b) => sortTimeStampDescending(a.created, b.created))[0]?.change
            .after as boolean)

    const leadBooking = findLeadBooking(updatedArea[0] as Partial<AreaStruct>, bookings, startOfDayInTZ)

    const result: AreaSummaryProjectionStruct = {
        bookings: bookings.map(b => {
            return {
                nrOfGuests: b.adults + b.children,
                adults: b.adults,
                children: b.children,
                checkinDate: b.checkinDate,
                checkoutDate: b.checkoutDate,
                status: b.status,
                guestName: b.guestName,
                notes: b.notes,
                bedSetup: b.bedSetup,
                areaKey: b.areaKey,
                key: b.key,
                optInDates: b.optInDates,
                optOutDates: b.optOutDates,
                guestCheckedIn: b.guestCheckedIn,
                guestCheckedOut: b.guestCheckedOut
            }
        }),
        currentTask: currentTask
            ? {
                  key: currentTask.key,
                  cleaning: currentTask.cleaning,
                  checklist: currentTask.checklist,
                  name: currentTask.name,
                  assignedTo: currentTask.assignedTo ?? [],
                  startDate: currentTask.startDate,
                  status: currentTask.status
              }
            : null,
        activeRule: updatedArea[0].activeRule
            ? {
                  customChar: updatedArea[0].activeRule.customChar,
                  name: updatedArea[0].activeRule.name,
                  description: updatedArea[0].activeRule.description,
                  checklistTasks: updatedArea[0].activeRule.checklistTasks ?? []
              }
            : null,
        extras: leadBooking?.extras ?? [],
        bookingNotes: leadBooking?.notes,
        area: updatedArea[0],
        dailyComments: (dailyComments || []).map(d => {
            return { key: d.key, comment: d.comment, date: d.date }
        }),
        assigned: currentTask?.assignedTo ?? [],
        activities: activities,
        priority: isPriority,
        bedSetup: leadBooking?.bedSetup,
        lastHousekeeping: lastCleaningTask
            ? {
                  cleaning: lastCleaningTask.cleaning!,
                  assignedTo: lastCleaningTask.assignedTo ?? []
              }
            : null
    }
    return { updatedArea, isPriority, leadBooking, result }
}
