import * as c from './constants'
import { AreaCleaningStatus, AreaStruct, Occupancy, RuleStruct, UserStruct, getMiniUserObject } from './dataObjects'
import * as areaData from './area-data'
import * as colors from './colors'

import moment from 'moment-timezone'

import { iOSColors } from './react-native-typography'
import { Firebase } from './firebase'

interface IIconObj {
    source: string | null | undefined
    width?: number | null | undefined
    height?: number | null | undefined
}

export function pickOccupancyButtonImage(type: string) {
    let imgName: string = c.IMG_OCCUPANCY_ALL_BUTTON
    if (type === c.OCCUPANCY_STAYOVER_80) {
        imgName = c.IMG_OCCUPANCY_STAYOVER_80_BUTTON
    } else if (type === c.OCCUPANCY_STAYOVER) {
        imgName = c.IMG_OCCUPANCY_STAYOVER_50_BUTTON
    } else if (type === c.OCCUPANCY_CHECKOUT) {
        imgName = c.IMG_OCCUPANCY_CHECKOUT_BUTTON
    } else if (type === c.OCCUPANCY_CHECKIN) {
        imgName = c.IMG_OCCUPANCY_CHECKIN_BUTTON
    } else if (type === c.OCCUPANCY_VACANT) {
        imgName = c.IMG_OCCUPANCY_VACANT_BUTTON
    } else if (type === c.OCCUPANCY_TURNOVER) {
        imgName = c.IMG_OCCUPANCY_TURNOVER_BUTTON
    }

    return imgName
}

export function pickCleaningStatusColor(cleaningStatus: AreaCleaningStatus, occupancy?: Occupancy) {
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
        if (occupancy) {
            if (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80) {
                return colors.rose_unclean
            }
        }
        return colors.red_spectaflow
    }
    if (cleaningStatus === c.CLEANING_STATUS_PREPARING || cleaningStatus === c.CLEANING_STATUS_PREPARING_PAUSE) {
        return colors.yello_spectaflow
    }
    if (cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
        return colors.curry_yellow_inspection
    }
    if (cleaningStatus === c.CLEANING_STATUS_CLEAN) {
        return iOSColors.green
    }
    if (cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB) {
        return colors.blue_spectaflow
    }

    if (cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE) {
        return iOSColors.midGray
    }
    if (cleaningStatus === c.CLEANING_STATUS_NO_SERVICE) {
        return colors.teal_sweeply
    }
    if (cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT) {
        return colors.purple_spectaflow
    }
    if (cleaningStatus === c.CLEANING_STATUS_ALL) {
        return iOSColors.black
    }
    return iOSColors.black
}

function pickTidyRoomColorIcon(cleaningStatus: AreaCleaningStatus, occupancy: Occupancy) {
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
        if (occupancy && (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80)) {
            return c.IMG_CLEANING_TASK_TIDY_ROOM_ROSE
        } else {
            return c.IMG_CLEANING_TASK_TIDY_ROOM_RED
        }
    }
    if (cleaningStatus === c.CLEANING_STATUS_PREPARING || cleaningStatus === c.CLEANING_STATUS_PREPARING_PAUSE) {
        return c.IMG_CLEANING_TASK_TIDY_ROOM_YELLOW
    }
    if (cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
        return c.IMG_CLEANING_TASK_TIDY_ROOM_CURRY
    }
    if (cleaningStatus === c.CLEANING_STATUS_CLEAN) {
        return c.IMG_CLEANING_TASK_TIDY_ROOM_GREEN
    }
    if (cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT) {
        return c.IMG_CLEANING_TASK_TIDY_ROOM_PURPLE
    }

    if (cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB) {
        return c.IMG_CLEANING_TASK_TIDY_ROOM_BLUE
    }

    if (cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE) {
        return c.IMG_CLEANING_TASK_TIDY_ROOM_GRAY
    }

    return null
}

function pickPrepareRoomIcon(cleaningStatus: AreaCleaningStatus, occupancy: Occupancy) {
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
        if (occupancy && (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80)) {
            return c.IMG_CLEANING_TASK_PREPARE_ROOM_ROSE
        } else {
            return c.IMG_CLEANING_TASK_PREPARE_ROOM_RED
        }
    }
    if (cleaningStatus === c.CLEANING_STATUS_PREPARING || cleaningStatus === c.CLEANING_STATUS_PREPARING_PAUSE) {
        return c.IMG_CLEANING_TASK_PREPARE_ROOM_YELLOW
    }
    if (cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
        return c.IMG_CLEANING_TASK_PREPARE_ROOM_CURRY
    }
    if (cleaningStatus === c.CLEANING_STATUS_CLEAN) {
        return c.IMG_CLEANING_TASK_PREPARE_ROOM_GREEN
    }
    if (cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT) {
        return c.IMG_CLEANING_TASK_PREPARE_ROOM_PURPLE
    }

    if (cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB) {
        return c.IMG_CLEANING_TASK_PREPARE_ROOM_BLUE
    }

    if (cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE) {
        return c.IMG_CLEANING_TASK_PREPARE_ROOM_GRAY
    }

    return null
}

function pickCheckoutCleaningIcon(cleaningStatus: AreaCleaningStatus, occupancy: Occupancy) {
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
        if (occupancy && (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80)) {
            return c.IMG_CLEANING_TASK_CHECKOUT_CLEANING_ROSE
        } else {
            return c.IMG_CLEANING_TASK_CHECKOUT_CLEANING_RED
        }
    }
    if (cleaningStatus === c.CLEANING_STATUS_PREPARING || cleaningStatus === c.CLEANING_STATUS_PREPARING_PAUSE) {
        return c.IMG_CLEANING_TASK_CHECKOUT_CLEANING_YELLOW
    }
    if (cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
        return c.IMG_CLEANING_TASK_CHECKOUT_CLEANING_CURRY
    }
    if (cleaningStatus === c.CLEANING_STATUS_CLEAN) {
        return c.IMG_CLEANING_TASK_CHECKOUT_CLEANING_GREEN
    }
    if (cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT) {
        return c.IMG_CLEANING_TASK_CHECKOUT_CLEANING_PURPLE
    }

    if (cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB) {
        return c.IMG_CLEANING_TASK_CHECKOUT_CLEANING_BLUE
    }

    if (cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE) {
        return c.IMG_CLEANING_TASK_CHECKOUT_CLEANING_GRAY
    }

    return null
}

export function pickCleaningActionIconSimple(occupancy: Occupancy) {
    if (!occupancy) {
        return null
    }
    const iconObj: IIconObj = { source: null, width: null, height: null }

    if (occupancy === c.OCCUPANCY_STAYOVER) {
        iconObj.width = c.ICON_TIDY_ROOM.width
        iconObj.height = c.ICON_TIDY_ROOM.height
        iconObj.source = c.IMG_CLEANING_TASK_TIDY_ROOM_WHITE

        return iconObj
    } else if (occupancy === c.OCCUPANCY_STAYOVER_80) {
        iconObj.width = c.ICON_PREPARE_ROOM.width
        iconObj.height = c.ICON_PREPARE_ROOM.height
        iconObj.source = c.IMG_CLEANING_TASK_PREPARE_ROOM_WHITE

        return iconObj
    } else if (occupancy === c.OCCUPANCY_CHECKOUT || occupancy === c.OCCUPANCY_TURNOVER) {
        iconObj.width = c.ICON_CHECKOUT_CLEANING.width
        iconObj.height = c.ICON_CHECKOUT_CLEANING.height
        iconObj.source = c.IMG_CLEANING_TASK_CHECKOUT_CLEANING_WHITE

        return iconObj
    } else {
        return null
    }
}

export function pickGuestCleaningActionIcon(occupancy: Occupancy) {
    if (!occupancy) {
        return null
    }
    const iconObj: IIconObj = { source: null, width: null, height: null }

    if (occupancy === c.OCCUPANCY_STAYOVER) {
        iconObj.width = 16
        iconObj.height = 16
        iconObj.source = c.IMG_CLEANING_TASK_TIDY_ROOM_WHITE

        return iconObj
    } else if (occupancy === c.OCCUPANCY_STAYOVER_80) {
        iconObj.width = c.ICON_PREPARE_ROOM.width
        iconObj.height = c.ICON_PREPARE_ROOM.height
        iconObj.source = c.IMG_CLEANING_TASK_PREPARE_ROOM_WHITE

        return iconObj
    } else if (occupancy === c.OCCUPANCY_VACANT) {
        iconObj.width = 16
        iconObj.height = 16
        iconObj.source = c.IMG_CLEANING_STATUS_NO_SERVICE_WHITE

        return iconObj
    } else if (occupancy === c.OCCUPANCY_CHECKOUT || occupancy === c.OCCUPANCY_TURNOVER) {
        iconObj.width = c.ICON_CHECKOUT_CLEANING.width
        iconObj.height = c.ICON_CHECKOUT_CLEANING.height
        iconObj.source = c.IMG_CLEANING_TASK_CHECKOUT_CLEANING_WHITE

        return iconObj
    } else {
        return null
    }
}

export function pickCleaningActionIcon(
    cleaningStatus: AreaCleaningStatus,
    occupancy: Occupancy,
    cleaningFrequency = 'daily',
    rules: RuleStruct[] = [],
    useCleaningStatusColor = true,
    usingProjections = false
) {
    if (!occupancy) {
        return null
    }

    const iconObj: IIconObj = { source: null, width: null, height: null }

    const WAS_FUR_EIN = !(
        occupancy === c.OCCUPANCY_CHECKIN ||
        occupancy === c.OCCUPANCY_STAYOVER ||
        occupancy === c.OCCUPANCY_STAYOVER_80 ||
        occupancy === c.OCCUPANCY_CHECKOUT ||
        occupancy === c.OCCUPANCY_TURNOVER ||
        occupancy === c.OCCUPANCY_VACANT
    )

    if (WAS_FUR_EIN) {
        iconObj.width = 20
        iconObj.height = 20
        iconObj.source = c.IMG_WAS_FUR_EIN
        return iconObj
    }

    if (occupancy === c.OCCUPANCY_STAYOVER) {
        if (
            !rules.find(x => (x.repeatType === 'custom' || x.repeatType === 'optin') && x.trigger === 'booking' && x.repeatInterval == 1) &&
            !usingProjections
        ) {
            return null
        }
        if (cleaningFrequency === c.CLEANING_RULES_CHECKOUT) {
            return null
        }
        iconObj.width = c.ICON_TIDY_ROOM.width
        iconObj.height = c.ICON_TIDY_ROOM.height
        if (!cleaningStatus) {
            iconObj.source = c.IMG_CLEANING_TASK_TIDY_ROOM_BLACK
            return iconObj
        } else if (cleaningStatus === c.CLEANING_STATUS_NO_SERVICE) {
            return null
        } else if (useCleaningStatusColor) {
            iconObj.source = pickTidyRoomColorIcon(cleaningStatus, occupancy)
            return iconObj
        } else {
            iconObj.source = c.IMG_CLEANING_TASK_TIDY_ROOM_WHITE
            return iconObj
        }
    } else if (occupancy === c.OCCUPANCY_STAYOVER_80) {
        if (
            !rules.find(
                x => (x.repeatType === 'custom' || x.repeatType === 'optin') && x.trigger === 'booking' && x.repeatInterval !== 1
            ) &&
            !usingProjections
        ) {
            return null
        }
        iconObj.width = c.ICON_PREPARE_ROOM.width
        iconObj.height = c.ICON_PREPARE_ROOM.height
        if (!cleaningStatus) {
            iconObj.source = c.IMG_CLEANING_TASK_PREPARE_ROOM_BLACK
        } else if (useCleaningStatusColor) {
            iconObj.source = pickPrepareRoomIcon(cleaningStatus, occupancy)
        } else {
            iconObj.source = c.IMG_CLEANING_TASK_PREPARE_ROOM_WHITE
        }
        return iconObj
    } else if (occupancy === c.OCCUPANCY_CHECKOUT || occupancy === c.OCCUPANCY_TURNOVER) {
        if (rules.length === 0 && !usingProjections) {
            return null
        }
        if (!rules.find(x => x.repeatType === 'checkout') && !usingProjections) {
            return null
        }
        iconObj.width = c.ICON_CHECKOUT_CLEANING.width
        iconObj.height = c.ICON_CHECKOUT_CLEANING.height
        if (!cleaningStatus) {
            iconObj.source = c.IMG_CLEANING_TASK_CHECKOUT_CLEANING_BLACK
        } else if (useCleaningStatusColor) {
            iconObj.source = pickCheckoutCleaningIcon(cleaningStatus, occupancy)
        } else {
            iconObj.source = c.IMG_CLEANING_TASK_CHECKOUT_CLEANING_WHITE
        }
        return iconObj
    } else {
        return null
    }
}

export function pickCheckinIcon(
    cleaningStatus: AreaCleaningStatus,
    occupancy: Occupancy,
    useCleaningStatusColor: boolean
): IIconObj | null {
    const iconObj: IIconObj = { source: null, width: 18, height: 18 }

    if (occupancy === c.OCCUPANCY_TURNOVER || occupancy === c.OCCUPANCY_CHECKIN) {
        if (!cleaningStatus) {
            iconObj.source = c.IMG_CLEANING_TASK_CHECK_IN_BLACK
        } else if (useCleaningStatusColor) {
            switch (cleaningStatus) {
                case c.CLEANING_STATUS_DIRTY:
                    iconObj.source = c.IMG_CLEANING_TASK_CHECK_IN_RED
                    break
                case c.CLEANING_STATUS_PREPARING:
                case c.CLEANING_STATUS_PREPARING_PAUSE:
                    iconObj.source = c.IMG_CLEANING_TASK_CHECK_IN_YELLOW
                    break
                case c.CLEANING_STATUS_INSPECTION:
                    iconObj.source = c.IMG_CLEANING_TASK_CHECK_IN_CURRY
                    break
                case c.CLEANING_STATUS_CLEAN:
                    iconObj.source = c.IMG_CLEANING_TASK_CHECK_IN_GREEN
                    break
                case c.CLEANING_STATUS_WAITING_FOR_CHECKOUT:
                    iconObj.source = c.IMG_CLEANING_TASK_CHECK_IN_PURPLE
                    break
                case c.CLEANING_STATUS_DO_NOT_DISTURB:
                    iconObj.source = c.IMG_CLEANING_TASK_CHECK_IN_BLUE
                    break
                case c.CLEANING_STATUS_OUT_OF_SERVICE:
                    iconObj.source = c.IMG_CLEANING_TASK_CHECK_IN_GRAY
                    break
                default:
                    iconObj.source = c.IMG_CLEANING_TASK_CHECK_IN_BLACK
                    break
            }
        } else {
            iconObj.source = c.IMG_CLEANING_TASK_CHECK_IN_WHITE
        }

        return iconObj
    }

    return null
}

export function pickPauseIcon(cleaningStatus: AreaCleaningStatus, useCleaningStatusColor: boolean) {
    const iconObj: IIconObj = { source: null, width: 18, height: 18 }
    if (cleaningStatus === c.CLEANING_STATUS_PREPARING_PAUSE) {
        if (!cleaningStatus) {
            iconObj.source = c.IMG_CLEANING_TASK_PAUSE_BLACK
        } else if (useCleaningStatusColor) {
            iconObj.source = c.IMG_CLEANING_TASK_PAUSE_YELLOW
        } else {
            iconObj.source = c.IMG_CLEANING_TASK_PAUSE_WHITE
        }
        return iconObj
    } else {
        return null
    }
}

export function pickInspectionIcon(cleaningStatus: AreaCleaningStatus, useCleaningStatusColor: boolean) {
    const iconObj: IIconObj = { source: null, width: c.ICON_INSPECTION.width, height: c.ICON_INSPECTION.height }
    if (cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
        if (!cleaningStatus) {
            iconObj.source = c.IMG_CLEANING_TASK_INSPECTION_BLACK
        } else if (useCleaningStatusColor) {
            iconObj.source = c.IMG_CLEANING_TASK_INSPECTION_CURRY
        } else {
            iconObj.source = c.IMG_CLEANING_TASK_INSPECTION_WHITE
        }
        return iconObj
    } else {
        return null
    }
}

export function pickPeopleIcon(
    cleaningStatus: AreaCleaningStatus,
    occupancy: Occupancy,
    isGuestCheckedIn: boolean,
    useCleaningStatusColor: boolean
) {
    if (
        isGuestCheckedIn ||
        occupancy === c.OCCUPANCY_STAYOVER ||
        occupancy === c.OCCUPANCY_STAYOVER_80 ||
        (occupancy === c.OCCUPANCY_CHECKOUT && cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT) ||
        (occupancy === c.OCCUPANCY_TURNOVER && cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT)
    ) {
        const iconObj = { source: null, width: 18, height: 15 }
        if (!cleaningStatus) {
            iconObj.source = c.IMG_CLEANING_TASK_PEOPLE_BLACK
        } else if (useCleaningStatusColor) {
            if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
                if (occupancy && (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80)) {
                    iconObj.source = c.IMG_CLEANING_TASK_PEOPLE_ROSE
                } else {
                    iconObj.source = c.IMG_CLEANING_TASK_PEOPLE_RED
                }
            }
            if (cleaningStatus === c.CLEANING_STATUS_PREPARING || cleaningStatus === c.CLEANING_STATUS_PREPARING_PAUSE) {
                iconObj.source = c.IMG_CLEANING_TASK_PEOPLE_YELLOW
            }

            if (cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
                iconObj.source = c.IMG_CLEANING_TASK_PEOPLE_CURRY
            }
            if (cleaningStatus === c.CLEANING_STATUS_CLEAN) {
                iconObj.source = c.IMG_CLEANING_TASK_PEOPLE_GREEN
            }
            if (cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT) {
                iconObj.source = c.IMG_CLEANING_TASK_PEOPLE_PURPLE
            }

            if (cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB) {
                iconObj.source = c.IMG_CLEANING_TASK_PEOPLE_BLUE
            }
            if (cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE) {
                iconObj.source = c.IMG_CLEANING_TASK_PEOPLE_GRAY
            }
            if (cleaningStatus === c.CLEANING_STATUS_NO_SERVICE) {
                iconObj.source = c.IMG_CLEANING_TASK_PEOPLE_TEAL
            }
        } else {
            iconObj.source = c.IMG_CLEANING_TASK_PEOPLE_WHITE
        }
        return iconObj
    }
    return null
}

export function isDirty50(rules: RuleStruct & { type: string; frequency: string }[], nrOfDaysStayed: number) {
    if (!rules) {
        return false
    }
    const rule = rules.find(item => item.type === 'booking_reoccuring_cleaning')
    if (!rule) {
        return false
    }

    if (!nrOfDaysStayed) {
        return false
    }

    const remainder = nrOfDaysStayed % parseInt(rule.frequency)

    if (nrOfDaysStayed > 0 && (remainder === 0 || remainder === parseInt(rule.frequency))) {
        return true
    }
    return false
}

export function pickCleaningStatusIcon(cleaningStatus: AreaCleaningStatus, useCleaningStatusColor = false) {
    if (!cleaningStatus) {
        return null
    }
    if (cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB) {
        const iconObj = { source: null, width: c.ICON_DND.width, height: c.ICON_DND.height }
        if (useCleaningStatusColor) {
            iconObj.source = c.IMG_CLEANING_STATUS_DO_NOT_DISTURB_BLUE
        } else {
            iconObj.source = c.IMG_CLEANING_STATUS_DO_NOT_DISTURB_WHITE
        }
        return iconObj
    } else if (cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE) {
        const iconObj = { source: null, width: c.ICON_OUT_OF_SERVICE.width, height: c.ICON_OUT_OF_SERVICE.height }
        if (useCleaningStatusColor) {
            iconObj.source = c.IMG_CLEANING_STATUS_OUT_OF_SERVICE_GRAY
        } else {
            iconObj.source = c.IMG_CLEANING_STATUS_OUT_OF_SERVICE_WHITE
        }
        return iconObj
    } else if (cleaningStatus === c.CLEANING_STATUS_NO_SERVICE) {
        const iconObj = { source: null, width: c.ICON_NO_SERVICE.width, height: c.ICON_NO_SERVICE.height }
        if (useCleaningStatusColor) {
            iconObj.source = c.IMG_CLEANING_STATUS_NO_SERVICE
        } else {
            iconObj.source = c.IMG_CLEANING_STATUS_NO_SERVICE_WHITE
        }
        return iconObj
    } else {
        return null
    }
}

export function cleaningStatusToHuman(cleaningStatus: AreaCleaningStatus, occupancy: Occupancy | null, firstIsCapital = true) {
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY && (occupancy === c.OCCUPANCY_CHECKOUT || occupancy === c.OCCUPANCY_TURNOVER)) {
        return '100% unclean'
    }
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY && occupancy === c.OCCUPANCY_STAYOVER_80) {
        return '80% unclean'
    }
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY && occupancy === c.OCCUPANCY_STAYOVER) {
        return '50% unclean'
    }
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
        if (firstIsCapital) {
            return 'Unclean'
        } else {
            return 'unclean'
        }
    }

    if (cleaningStatus === c.CLEANING_STATUS_PREPARING) {
        if (firstIsCapital) {
            return 'Preparing'
        } else {
            return 'preparing'
        }
    }
    if (cleaningStatus === c.CLEANING_STATUS_PREPARING_PAUSE) {
        if (firstIsCapital) {
            return 'Preparing pause'
        } else {
            return 'preparing pause'
        }
    }
    if (cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
        if (firstIsCapital) {
            return 'Inspection'
        } else {
            return 'inspection'
        }
    }
    if (cleaningStatus === c.CLEANING_STATUS_CLEAN) {
        if (firstIsCapital) {
            return 'Clean'
        } else {
            return 'clean'
        }
    }

    if (cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT) {
        if (firstIsCapital) {
            return 'Waiting for checkout'
        } else {
            return 'waiting for checkout'
        }
    }

    if (cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB) {
        if (firstIsCapital) {
            return 'Do not disturb'
        } else {
            return 'do not disturb'
        }
    }

    if (cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE) {
        if (firstIsCapital) {
            return 'Out of service'
        } else {
            return 'out of service'
        }
    }

    if (cleaningStatus === c.CLEANING_STATUS_NO_SERVICE) {
        if (firstIsCapital) {
            return 'No service'
        } else {
            return 'no service'
        }
    }

    if (cleaningStatus === c.CLEANING_STATUS_ALL) {
        if (firstIsCapital) {
            return 'All'
        } else {
            return 'all'
        }
    }

    return 'Other'
}

export function isToday(date: number) {
    const d1 = moment().startOf('day').valueOf()

    const d2 = moment(date).startOf('day').valueOf()

    return d1 === d2
}

export function durationToText(startMoment: moment.Moment, endMoment: moment.Moment, showPostFix = false) {
    const min = endMoment.diff(startMoment, 'minutes')

    const num = min
    const hours = num / 60
    const rhours = Math.floor(hours)
    const minutes = (hours - rhours) * 60
    const rminutes = Math.round(minutes)
    let hoursPostfix = ''
    let minPostfix = ''

    if (rhours > 1) {
        hoursPostfix = 's'
    }

    if (minutes !== 1) {
        minPostfix = 's'
    }

    let postfix = ''
    if (showPostFix) {
        postfix = ' ago'
    }

    if (rhours >= 1) {
        return rhours + ' hour' + hoursPostfix + ' and ' + rminutes + ' minute' + minPostfix + postfix
    }
    return rminutes + ' minute' + minPostfix + postfix
}

export async function changeCleaningStatus(
    firebase: Firebase,
    currentUser: UserStruct,
    area: AreaStruct & { rules: RuleStruct[] },
    cleaningStatus: AreaCleaningStatus,
    rule: RuleStruct | null = null
) {
    area.cleaningStatus = cleaningStatus

    const areaObject: Partial<AreaStruct> & { rules?: RuleStruct[] } = {}

    areaObject.lastStatusChange = {
        user: getMiniUserObject(currentUser),
        updated: moment().valueOf()
    }
    if (rule) {
        let rules: RuleStruct[] = []
        if (area.rules) {
            rules = area.rules
        }
        rules.push(rule)
        areaObject.rules = rules
    } else {
        areaObject.cleaningStatus = cleaningStatus
    }
    await areaData.updateArea(firebase, area.key, areaObject, currentUser)
}

export function pickDailyCommentIcon(cleaningStatus: AreaCleaningStatus, occupancy: Occupancy) {
    if (cleaningStatus === null && occupancy === null) {
        return c.IMG_CLEANING_TASK_DAILY_COMMENT_WHITE
    }
    if (cleaningStatus === null) {
        return c.IMG_CLEANING_TASK_DAILY_COMMENT_BLACK
    }
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
        if (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80) {
            return c.IMG_CLEANING_TASK_DAILY_COMMENT_ROSE
        } else {
            return c.IMG_CLEANING_TASK_DAILY_COMMENT_RED
        }
    }
    if (cleaningStatus === c.CLEANING_STATUS_PREPARING || cleaningStatus === c.CLEANING_STATUS_PREPARING_PAUSE) {
        return c.IMG_CLEANING_TASK_DAILY_COMMENT_YELLOW
    }
    if (cleaningStatus === c.CLEANING_STATUS_CLEAN) {
        return c.IMG_CLEANING_TASK_DAILY_COMMENT_GREEN
    }
    if (cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
        return c.IMG_CLEANING_TASK_DAILY_COMMENT_CURRY
    }
    if (cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT) {
        return c.IMG_CLEANING_TASK_DAILY_COMMENT_PURPLE
    }

    if (cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB) {
        return c.IMG_CLEANING_TASK_DAILY_COMMENT_BLUE
    }

    if (cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE) {
        return c.IMG_CLEANING_TASK_DAILY_COMMENT_GRAY
    }

    if (cleaningStatus === c.CLEANING_STATUS_NO_SERVICE) {
        return c.IMG_CLEANING_TASK_DAILY_COMMENT_TEAL
    }

    return null
}

export function pickAreaNoteIcon(cleaningStatus: AreaCleaningStatus | null, occupancy: Occupancy | null) {
    if (cleaningStatus === null && occupancy === null) {
        return c.IMG_CLEANING_TASK_AREA_NOTE_WHITE
    }
    if (cleaningStatus === null) {
        return c.IMG_CLEANING_TASK_AREA_NOTE_BLACK
    }
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
        if (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80) {
            return c.IMG_CLEANING_TASK_AREA_NOTE_ROSE
        } else {
            return c.IMG_CLEANING_TASK_AREA_NOTE_RED
        }
    }
    if (cleaningStatus === c.CLEANING_STATUS_PREPARING || cleaningStatus === c.CLEANING_STATUS_PREPARING_PAUSE) {
        return c.IMG_CLEANING_TASK_AREA_NOTE_YELLOW
    }
    if (cleaningStatus === c.CLEANING_STATUS_CLEAN) {
        return c.IMG_CLEANING_TASK_AREA_NOTE_GREEN
    }
    if (cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
        return c.IMG_CLEANING_TASK_AREA_NOTE_CURRY
    }
    if (cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT) {
        return c.IMG_CLEANING_TASK_AREA_NOTE_PURPLE
    }

    if (cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB) {
        return c.IMG_CLEANING_TASK_AREA_NOTE_BLUE
    }

    if (cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE) {
        return c.IMG_CLEANING_TASK_AREA_NOTE_GRAY
    }

    if (cleaningStatus === c.CLEANING_STATUS_NO_SERVICE) {
        return c.IMG_CLEANING_TASK_AREA_NOTE_TEAL
    }

    return null
}

export function pickBookingNoteIcon(cleaningStatus: AreaCleaningStatus, occupancy: Occupancy) {
    if (cleaningStatus === null && occupancy === null) {
        return c.IMG_CLEANING_TASK_BOOKING_NOTE_WHITE
    }
    if (cleaningStatus === null) {
        return c.IMG_CLEANING_TASK_BOOKING_NOTE_BLACK
    }
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
        if (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80) {
            return c.IMG_CLEANING_TASK_BOOKING_NOTE_ROSE
        } else {
            return c.IMG_CLEANING_TASK_BOOKING_NOTE_RED
        }
    }
    if (cleaningStatus === c.CLEANING_STATUS_PREPARING || cleaningStatus === c.CLEANING_STATUS_PREPARING_PAUSE) {
        return c.IMG_CLEANING_TASK_BOOKING_NOTE_YELLOW
    }
    if (cleaningStatus === c.CLEANING_STATUS_CLEAN) {
        return c.IMG_CLEANING_TASK_BOOKING_NOTE_GREEN
    }
    if (cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
        return c.IMG_CLEANING_TASK_BOOKING_NOTE_CURRY
    }
    if (cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT) {
        return c.IMG_CLEANING_TASK_BOOKING_NOTE_PURPLE
    }

    if (cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB) {
        return c.IMG_CLEANING_TASK_BOOKING_NOTE_BLUE
    }

    if (cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE) {
        return c.IMG_CLEANING_TASK_BOOKING_NOTE_GRAY
    }

    return null
}
