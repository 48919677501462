export interface Urls {
    marketingSite: string
    webappSite: string
    appStore: string
    playStore: string
    mobileHowTo: string
    helpEmail: string
    guide: string
}

export interface Accounting {
    productFamily: string
    autoCollection: string
    site: string
    api_key: string
    publishable_key: string
    defaultPlan: string
}

export type MiniBrandStruct = {
    name: string
    apiKey: string
    authDomain: string
    databaseURL: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    dynamicLinkPrefix: string
    bundleId: string
    iosAppStoreId: string
    appId: string
    measurementId: string
    urls: {
        marketingSite: string
        webappSite: string
        appStore: string
        playStore: string
        mobileHowTo: string
        helpEmail: string
        guide: string
    }
    accounting: {
        productFamily: string
        autoCollection: string
        site: string
        api_key: string
        publishable_key: string
        defaultPlan: string
    }
}

export const SPECTAFLOW_CONFIG: MiniBrandStruct = {
    name: 'Spectaflow',
    apiKey: 'AIzaSyDdWz1y_2d2q4gjXQDHVM8BRJHD0WBLtCI',
    authDomain: 'spectaflow.firebaseapp.com',
    databaseURL: 'https://spectaflow.firebaseio.com',
    storageBucket: 'spectaflow.appspot.com',
    messagingSenderId: '573006853212',
    projectId: 'spectaflow',
    dynamicLinkPrefix: 'https://spectaflow.page.link',
    bundleId: 'com.spectaflow',
    iosAppStoreId: '1438587806',
    appId: '1:573006853212:web:4bcc2f13949dfdf5586299',
    measurementId: 'G-GLX1YBHZT7',
    urls: {
        marketingSite: 'https://spectaflow.com/',
        webappSite: 'https://app.spectaflow.com',
        appStore: 'https://itunes.apple.com/us/app/spectaflow/id1438587806?ls=1&mt=8',
        playStore: 'https://play.google.com/store/apps/details?id=com.spectaflow&hl=en',
        mobileHowTo: 'https://spectaflow.com/tutorial#mobile_how_to',
        helpEmail: 'support@getsweeply.com',
        guide:
            'https://docs.google.com/document/d/e/2PACX-1vQ5GQV-DPWRk28OKc27zsaNB_FNLKeuDhvVq94aHcD-Cb2Ga4teCGdXxLOUI7P7QlTOCq1Ho4ljqW9F/pub?embedded=true'
    },
    accounting: {
        productFamily: 'sweeply',
        autoCollection: 'off',
        site: 'sweeply-test',
        api_key: 'test_w7xwSCH63nh7cupp9lAck6Sjcuf155mQ7E',
        publishable_key: 'test_tZlCGWqrculat83u2dtg26hzNCTCa6iDC',
        defaultPlan: ''
    }
}

export const SPECTATEST_CONFIG: MiniBrandStruct = {
    name: 'Spectatest',
    apiKey: 'AIzaSyCJWDDjov-mRgLr-tdJbN4hk0bC3zHjo5U',
    authDomain: 'specta-test.firebaseapp.com',
    databaseURL: 'https://specta-test.firebaseio.com',
    projectId: 'specta-test',
    storageBucket: 'specta-test.appspot.com',
    messagingSenderId: '43511078580',
    dynamicLinkPrefix: 'https://spectatest.page.link',
    bundleId: 'com.spectaflow.spectatest',
    iosAppStoreId: '1438587806',
    appId: '1:43511078580:web:9c006b10a8893ba796c1e1',
    measurementId: 'G-P2JW4GCGE1',
    urls: {
        marketingSite: 'https://getsweeply.com/',
        webappSite: 'https://app.getsweeply.dev',
        appStore: 'https://itunes.apple.com/us/app/spectaflow/id1438587806?ls=1&mt=8',
        playStore: 'https://play.google.com/store/apps/details?id=com.spectaflow&hl=en',
        mobileHowTo: 'https://spectaflow.com/tutorial#mobile_how_to',
        helpEmail: 'support@getsweeply.com',
        guide:
            'https://docs.google.com/document/d/e/2PACX-1vQ5GQV-DPWRk28OKc27zsaNB_FNLKeuDhvVq94aHcD-Cb2Ga4teCGdXxLOUI7P7QlTOCq1Ho4ljqW9F/pub?embedded=true'
    },
    accounting: {
        productFamily: 'sweeply',
        autoCollection: 'on',
        site: 'sweeply-test',
        api_key: 'test_w7xwSCH63nh7cupp9lAck6Sjcuf155mQ7E',
        publishable_key: 'test_tZlCGWqrculat83u2dtg26hzNCTCa6iDC',
        defaultPlan: 'pro'
        // defaultPlan: 'gn9bNxRLcQOygxHFrBuV'
        // defaultPlan: 'Pronto-Pronto-Basic'
    }
}

export const SWEEPLY_CONFIG: MiniBrandStruct = {
    name: 'Sweeply',
    apiKey: 'AIzaSyBNG6OcHaO2N1M-q6WA2md7WizlrKR_vHE',
    authDomain: 'godopronto.firebaseapp.com',
    databaseURL: 'https://godopronto.firebaseio.com',
    projectId: 'godopronto',
    storageBucket: 'godopronto.appspot.com',
    messagingSenderId: '232777971349',
    dynamicLinkPrefix: 'https://sweeply.page.link',
    bundleId: 'com.spectaflow.sweeply',
    iosAppStoreId: '1612273055',
    appId: '1:232777971349:web:c84b3d1ed1ada3a60b8d9c',
    measurementId: 'G-9JPTHHJPTX',
    urls: {
        marketingSite: 'https://www.getsweeply.com/',
        webappSite: 'https://app.getsweeply.com',
        appStore: 'https://apps.apple.com/us/app/sweeply-app/id1612273055',
        playStore: 'https://play.google.com/store/apps/details?id=com.spectaflow.sweeply',
        mobileHowTo: 'http://help.getsweeply.com',
        helpEmail: 'support@getsweeply.com',
        guide: 'http://help.getsweeply.com'
    },
    accounting: {
        productFamily: 'sweeply',
        autoCollection: 'on',
        site: 'sweeply',
        api_key: 'live_TD1RvybgdoaEioOqTSknOpjoJpHYeJ0C',
        publishable_key: 'live_D3seyhjcuqqjukSupsiZcdEjvcKAVWKPTf',
        defaultPlan: 'pro'
    }
}

export const PROJECTS: Record<string, MiniBrandStruct> = {
    spectaflow: SPECTAFLOW_CONFIG,
    'specta-test': SPECTATEST_CONFIG,
    sweeply: SWEEPLY_CONFIG,
    godopronto: SWEEPLY_CONFIG
}
