import React from 'react'
import { ActivityIndicator, FlatList, Image, View } from 'react-native-web'
import { getColumnProps, getRowProps } from 'react-flexbox-grid'
import Modal from 'react-modal'
import moment from 'moment-timezone'
import { iOSColors } from '@shared/react-native-typography'
import Notifications from 'react-notify-toast'
import Assignees from '../components/assignees'
import AssigneesView from '../components/assignees-view'
import firebase, { tasksRepo } from '../utils/firebase'
import * as colors from '@shared/colors'
import * as areaData from '@shared/area-data'
import * as chelpers from '@shared/cleaning-helpers'
import * as taskHelpers from '@shared/task-helpers'
import * as taskData from '@shared/task-data'
import * as issueData from '@shared/issue-data'
import * as dataObjects from '@shared/dataObjects'
import { createActivity } from '@shared/activity-data'

import * as analytics from '@shared/analytics'
import CleaningStatusSelect from './cleaning-status-select'
import OccupancySelect from './occupancy-select'
import DailyCommentTextInput from './daily-comment-text-input'
import AreaNoteTextInput from './area-note-text-input'

import * as data from './dashboard/data'
import { findLeadBooking } from '@shared/booking-service'

import ActivityRow from './activity-row'

import * as brand from '@shared/brand'
import * as c from '@shared/constants'
import { durationToText, getTimerDisplay } from '@shared/timer-helpers'

import { calculateCleaningStatus } from '@shared/cleaning-calculator'

import { HoverableImageButton } from '../components/buttons/hoverable-image-button'
import DatePicker from '../components/hoverable-date-picker'
import * as uc from '../utils/constants'

import IssueRow from '../issues/IssuesBody/IssueRow/index'
import AIssue from '../issues/aissue-modal'
import { FeatureLockedSignal, SubscriptionCheck, UpgradeMessageContent } from '../components/upgrade-signals'

import { AreaBredCrumbs } from '../components/area-bread-crumbs'
import { CloseModalButton } from '../components/buttons/close-modal-button'
import * as hkhelpers from '../utils/housekeeping-helpers'
import { DueDateButton } from '../components/buttons/DueDateButton'
import * as helpers from '@shared/helpers'
import UsersList from '../users/users-list'
import * as styles from '../housekeeping/dashboard/styles'
import { TasksContext } from '../tasks/TasksContext'
import { assignUsers } from '../tasks/actions'

import { CleaningScheduleLocal } from '../modules/Guests/GuestHome/pages/CleaningSchedule/CleaningScheduleLocal'
import ExtraServicesInfo from './housekeeping-detail/services'
import { withRouter } from 'react-router-dom'
import { GuestQrButton } from './guest-qr-button'
import { getBookingDisplay } from '@shared/get-booking-display'
import { userIsAllowed } from '@shared/roles/roles'
import { Checkbox } from '../components/forms/Checkbox'
import BoxIcon from './housekeeping-box/box-icon'
import CircleNumber from '../components/CircleChar/index'

class HousekeepingModal extends React.Component {
    static contextType = TasksContext

    constructor(props) {
        super(props)

        this.rowProps = getRowProps(props)
        this.colProps = getColumnProps(props)
        this.onCleaningStatusChange = this.onCleaningStatusChange.bind(this)
        this.onOccupancyPress = this.onOccupancyPress.bind(this)
        this.renderBookingInfo = this.renderBookingInfo.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.getData = this.getData.bind(this)

        this.prepareArea = this.prepareArea.bind(this)

        this.onDateChange = this.onDateChange.bind(this)
        this.fetchLastCleaningTask = this.fetchLastCleaningTask.bind(this)

        this.onCleaningPriorityPress = this.onCleaningPriorityPress.bind(this)
        this.renderPriorityButton = this.renderPriorityButton.bind(this)

        this.shouldRenderDailyCommentIcon = this.shouldRenderDailyCommentIcon.bind(this)

        this.renderDeveloperSection = this.renderDeveloperSection.bind(this)
        this.renderActivities = this.renderActivities.bind(this)
        this.renderHeader = this.renderHeader.bind(this)
        this.renderDailyComment = this.renderDailyComment.bind(this)

        this.setTimerOnUpdate = this.setTimerOnUpdate.bind(this)
        this.startCleaningTimer = this.startCleaningTimer.bind(this)
        this.stopCleaningTimer = this.stopCleaningTimer.bind(this)
        this.updateCleaningTimer = this.updateCleaningTimer.bind(this)

        this.startIssueListener = this.startIssueListener.bind(this)
        this.onIssuesUpdate = this.onIssuesUpdate.bind(this)
        this.renderIssueList = this.renderIssueList.bind(this)
        this.aIssueOpenModal = this.aIssueOpenModal.bind(this)
        this.aIssueCloseModal = this.aIssueCloseModal.bind(this)
        this.onPressIssueRow = this.onPressIssueRow.bind(this)

        this.onAvailable = this.onAvailable.bind(this)
        this.onNotAvailable = this.onNotAvailable.bind(this)

        this.openUpgradeMessage = this.openUpgradeMessage.bind(this)
        this.closeUpgradeMessage = this.closeUpgradeMessage.bind(this)

        this.onAssignPress = this.onAssignPress.bind(this)
        this.assignForNotToday = this.assignForNotToday.bind(this)
        this.unassignForNotToday = this.unassignForNotToday.bind(this)

        this.renderCleaningSchedule = this.renderCleaningSchedule.bind(this)
        this.renderDailyComment = this.renderDailyComment.bind(this)
        this.renderAreaNotes = this.renderAreaNotes.bind(this)
        this.renderBookingNotes = this.renderBookingNotes.bind(this)
        this.renderRule = this.renderRule.bind(this)
        this.renderLastHouskeeping = this.renderLastHouskeeping.bind(this)
        this.renderIssueList = this.renderIssueList.bind(this)
        this.renderActivities = this.renderActivities.bind(this)
        this.renderChecklist = this.renderChecklist.bind(this)

        this.deleteDueDate = this.deleteDueDate.bind(this)

        this.state = {
            aIssueModalIsOpen: false,
            selectedStatus: this.props.area.cleaningStatus,
            area: this.props.area,
            dataIsReady: false,
            selectedDateNumber: this.props.selectedDateNumber,
            cleaningPriority: this.props.area ? this.props.area.priority : null,
            lastCleaningTaskKey: this.props.area && this.props.area.lastCleaningTaskKey ? this.props.area.lastCleaningTaskKey : null,
            activities: [],
            assignHover: false,
            cleaningPriorityHover: false,
            closeHover: false,
            issues: [],
            task: null,
            notAccessibleFeature: null,
            availableUpgrades: null,
            showUpgradeMessage: false,
            hasSubscriptionAccess: false,
            leadBooking: null
        }

        this.areaToday = this.props.area
        this.UNIT_HEADER_FONT_SIZE = 17

        this.selectedDate = moment(this.props.selectedDateNumber)

        this.IS_TASKS_SCREEN = this.props.context === 'tasks'

        this.cleaningActionState = c.CLEANING_ACTION_STOP
    }

    componentDidMount() {
        let selectedDateNumber = this.props.selectedDateNumber
        if (!selectedDateNumber) {
            selectedDateNumber = this.props.getSelectedDateNumber()
            this.setState({ selectedDateNumber: selectedDateNumber })
        }
        const task = this.props.task ? this.props.task : this.props.area && this.props.area.task ? this.props.area.task : null
        this.setState({
            activities: this.props.area.activities,
            task: task,
            dueDateNumber: task ? task.startDate : null,
            assignedTo: HousekeepingModal.createAssignedToObj(this.props.task)
        })

        this.bookings = this.props.area.bookings || []
        this.activities = this.props.area.activities || []
        this.dailyComments = this.props.dailyComments || []
        this.rules = this.props.area.rules || []
        this.extras = this.props.area.bookings
            .filter(x => x.extras && x.extras.length > 0)
            .map(x => x.extras)
            .flat()
            .filter(x => x.date === this.state.selectedDateNumber)

        this.prepareArea(this.props.area, selectedDateNumber)

        if (this.state.lastCleaningTaskKey) {
            this.fetchLastCleaningTask(this.state.lastCleaningTaskKey)
        } else this.updateCleaningTimer()
        this.startIssueListener()

        this.unsubscribeActivities = firebase
            .firestore()
            .collection('areas')
            .doc(this.state.area.key)
            .collection('activities')
            .where('date', '==', selectedDateNumber)
            .onSnapshot(activiesSnap => {
                const activities = activiesSnap.docs
                    .map(a => a.data())
                    .sort((a, b) => helpers.sortTimeStampDescending(a.created, b.created))

                this.activities = activities
                this.setState({ activities })
            })
    }

    onAvailable() {
        this.setState({ hasSubscriptionAccess: true })
    }

    onNotAvailable(notAccessibleFeature, availableUpgrades) {
        this.setState({ hasSubscriptionAccess: false, notAccessibleFeature, availableUpgrades })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.area.lastCleaningTaskKey !== state.lastCleaningTaskKey) {
            return { lastCleaningTaskKey: props.area.lastCleaningTaskKey }
        }
        if (
            chelpers.isToday(state.selectedDateNumber) &&
            (props.area.cleaningStatus !== state.selectedStatus || props.area.occupancy !== state.area.occupancy) &&
            props.context !== 'tasks'
        ) {
            return {
                area: props.area,
                selectedStatus: props.area.cleaningStatus,
                activities: props.area.activities
            }
        }

        if (
            !HousekeepingModal.hasSameAssignees(props.assignedTo, state.assignedTo) &&
            props.selectedDateNumber === state.selectedDateNumber
        ) {
            return {
                task: props.task,
                dueDateNumber: props.task ? props.task.startDate : null,
                assignedTo: HousekeepingModal.createAssignedToObj(props.task)
            }
        }

        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.lastCleaningTaskKey !== this.state.lastCleaningTaskKey) {
            if (this.state.lastCleaningTaskKey) {
                this.fetchLastCleaningTask(this.state.lastCleaningTaskKey)
            }
        }
        if (
            prevState.area.activities &&
            this.state.area.activities &&
            prevState.area.activities.length !== this.state.area.activities.length
        ) {
            this.prepareArea(this.state.area, this.state.selectedDateNumber)
        }

        if (!HousekeepingModal.hasSameAssignees(prevState.assignedTo, this.state.assignedTo)) {
            this.prepareArea(this.state.area, this.state.selectedDateNumber)
        }

        return null
    }

    startIssueListener() {
        if (this.unsubscribeIssueListener) {
            this.unsubscribeIssueListener()
        }

        this.unsubscribeIssueListener = issueData
            .onFilteredIssues(firebase, this.props.currentUser.organizationKey, [c.ISSUE_OPEN, c.ISSUE_ASSIGNED], this.state.area.key)
            .onSnapshot(this.onIssuesUpdate.bind(this))
    }

    renderIssueList() {
        return (
            <View
                style={{
                    width: '100%',
                    borderWidth: 0
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        borderColor: 'red',
                        borderWidth: 0,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <span
                            style={{
                                fontWeight: '600',
                                color: iOSColors.black,
                                fontSize: this.UNIT_HEADER_FONT_SIZE + 2
                            }}>
                            Issues
                        </span>
                        <span style={{ marginLeft: 6, marginBottom: 0, fontSize: this.UNIT_HEADER_FONT_SIZE - 3 }}>
                            ({this.state.issues ? this.state.issues.length : ''})
                        </span>
                    </View>
                    <SubscriptionCheck
                        featureId="issues"
                        onAvailable={() => null}
                        onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                            this.issueFeatureNotAvailable = true
                            return (
                                <FeatureLockedSignal
                                    showLock
                                    isActive
                                    onClick={() => {
                                        this.openUpgradeMessage(notAccessibleFeature, availableUpgrades)
                                    }}
                                />
                            )
                        }}
                    />
                </View>
                <FlatList
                    contentContainerStyle={{
                        paddingTop: 12,

                        borderColor: 'blue'
                    }}
                    initialNumToRender={5}
                    data={this.state.issues}
                    renderItem={item => {
                        const currentIssue = item.item

                        return (
                            <IssueRow
                                disabled={this.issuesFeatureNotAvailable}
                                context={uc.ISSUE_ROW_CONTEXT_HOUSEKEEPING_MODAL}
                                key={item.key + this.state.searchValue}
                                issue={currentIssue}
                                checked={false}
                                checkedIssues={this.state.checkedIssues}
                                addCheckedIssue={this.addCheckedIssue}
                                removeCheckedIssue={this.removeCheckedIssue}
                                onPressIssueRow={this.onPressIssueRow}
                                onPressHashTag={this.onPressHashTag}
                                onSearchPress={this.onSearch}
                                currentUser={this.props.currentUser}
                            />
                        )
                    }}
                    stickySectionHeadersEnabled={false}
                    showsVerticalScrollIndicator={false}
                />
            </View>
        )
    }

    onIssuesUpdate(snap) {
        this.hashTags = []
        this.setState({ issues: snap })
    }

    componentWillUnmount() {
        // this.stopCleaningTimer(false)
        if (this.unsubscribeIssueListener) {
            this.unsubscribeIssueListener()
        }
        if (this.unsubscribeActivities) {
            this.unsubscribeActivities()
        }
    }

    static createAssignedToObj(task) {
        return task && task.assignedTo ? task.assignedTo : [{ name: 'empty' }]
    }

    static hasSameAssignees(assignedTo1, assignedTo2) {
        if (!assignedTo1) {
            return false
        }

        if (!assignedTo2) {
            return false
        }

        if (assignedTo1.length !== assignedTo2.length) {
            return false
        }
        for (let i = 0; i < assignedTo1.length; i++) {
            if (assignedTo1[i].key !== assignedTo2[i].key) {
                return false
            }
        }

        return true
    }

    async getData(date) {
        const promises = [
            data.getBookings(firebase, this.props.currentUser.organizationKey, date, this.state.area.key),
            areaData.getActivities(firebase, this.state.area.key, date),
            data.getDailyComments(firebase, this.props.currentUser.organizationKey, date, this.state.area.key),
            taskData.getTasks(
                taskData.getHousekeepingTasksQuery(
                    firebase,
                    this.props.currentUser.organizationKey,
                    date,
                    [c.TASK_ASSIGNED, c.TASK_OPEN, c.TASK_COMPLETE],
                    this.state.area.key
                )
            ),
            this.getNextBooking(date),
            data.getRules(firebase, this.props.currentUser.organizationKey)
        ]
        const result = await Promise.all(promises).then(values => {
            this.bookings = values[0]
            this.activities = values[1]
            this.dailyComments = values[2]
            this.extras = this.bookings
                .filter(x => x.extras && x.extras.length > 0)
                .map(x => x.extras)
                .flat()
                .filter(x => x.date === this.state.selectedDateNumber)
            this.rules = values[5]

            const task = taskHelpers.findNewestTask(values[3])

            let nextBookings = values[4]
            if (nextBookings && nextBookings.length > 0) {
                this.bookings.push(nextBookings[0])
            }

            this.prepareArea(this.state.area, date)

            return {
                activities: this.activities,
                task,
                dueDateNumber: task ? task.startDate : null,
                assignedTo: HousekeepingModal.createAssignedToObj(task)
            }
        })

        this.props.setCurrentTask && this.props.setCurrentTask(result.task)
        this.setState({ ...result })
    }

    onDateChange(d) {
        this.selectedDate = moment(d)
        const date = moment(d).startOf('day').valueOf()
        if (this.props.context === 'tasks') {
            const { setModalDaySelected } = this.context
            setModalDaySelected(date)
        }
        this.setState({
            showDatePickerTodayButton: !chelpers.isToday(date),
            renderDailyCommentIcon: false,
            selectedDateNumber: date,
            dataIsReady: false
        })

        this.getData(date)
    }

    async getNextBooking(date) {
        let tomorrow = moment(date).add(1, 'days')

        let bookingsQuery = firebase
            .firestore()
            .collection('bookings')
            .where('organizationKey', '==', this.props.currentUser.organizationKey)
            .where('areaKey', '==', this.state.area.key)
            .where('checkoutDate', '>=', tomorrow.valueOf())
            .orderBy('checkoutDate', 'asc')

        let bookingsRef = await bookingsQuery.limit(2).get() // we get two bookings since the first one might be one that is currently checked in

        let bookings = bookingsRef.docs.map(b => b.data())

        bookings = bookings.filter(b => b.status && b.status.toLowerCase() !== 'cancelled').filter(b => b.checkinDate > date)

        return bookings
    }

    async prepareArea(area, selectedDateNumber) {
        const todaysBookings = this.bookings.filter(b => b.bookingDates.includes(selectedDateNumber.toString()))
        const futureBookings = this.bookings.filter(b => !b.bookingDates.includes(selectedDateNumber.toString()))

        const [calculatedArea] = await calculateCleaningStatus(
            firebase,
            [area],
            selectedDateNumber,
            this.props.currentOrganization,
            todaysBookings,
            null,
            this.rules
        )

        let leadBooking = findLeadBooking(calculatedArea, this.bookings, selectedDateNumber)

        let isFutureBooking = true
        if (leadBooking && leadBooking.bookingDates.includes(selectedDateNumber.toString())) {
            isFutureBooking = false
        } else {
            let nextBookings = await this.getNextBooking(selectedDateNumber)
            leadBooking = nextBookings[0]

            if (
                this.bookings.length > 0 &&
                nextBookings.length > 0 &&
                this.bookings.filter(b => b.key === nextBookings[0].key).length === 0
            ) {
                this.bookings.push(nextBookings[0])
            }
        }

        const bookingDisplay = getBookingDisplay(leadBooking, calculatedArea, moment(selectedDateNumber))

        setTimeout(() => {
            this.setState({
                area: calculatedArea,
                selectedStatus: calculatedArea.cleaningStatus,
                isFutureBooking,
                leadBooking: leadBooking,
                guestName: bookingDisplay.guestName,
                guestCount: bookingDisplay.guestCount,
                bedSetup: bookingDisplay.bedSetup,
                occupancyText: bookingDisplay.occupancyText,
                occupancyState: bookingDisplay.occupancyState,
                bookingNotes: bookingDisplay.notes,
                guestComments: bookingDisplay.guestComments,
                dataIsReady: true,
                DEUBUG_todaysBookings: todaysBookings,
                DEUBUG_futureBookings: futureBookings
            })
        })
    }

    setTimerOnUpdate(currentTask) {
        // const HAS_CLEANING_OBJ = !!(currentTask && currentTask.key && currentTask.cleaning)
        // if (HAS_CLEANING_OBJ) {
        //     const cleaningObj = currentTask.cleaning
        //     const timerDisplay = this.getTimerDisplay(cleaningObj)

        //     const play = cleaningObj.play
        //     const pause = cleaningObj.pause
        //     const start = cleaningObj.start
        //     const end = cleaningObj.end

        //     if (!end && play && !pause) {
        //         this.cleaningActionState = c.CLEANING_ACTION_PLAY
        //         this.startCleaningTimer(cleaningObj)
        //     } else if (!end && play && pause && play.length === pause.length) {
        //         this.cleaningActionState = c.CLEANING_ACTION_PAUSE
        //         this.stopCleaningTimer(true, true, timerDisplay)
        //     } else if (!end && play && pause && play.length > pause.length) {
        //         this.cleaningActionState = c.CLEANING_ACTION_PLAY
        //         this.startCleaningTimer(cleaningObj)
        //     } else if (end) {
        //         this.cleaningActionState = c.CLEANING_ACTION_STOP
        //         this.stopCleaningTimer(true, false)
        //     } else if (start) {
        //         this.cleaningActionState = c.CLEANING_ACTION_PLAY
        //         this.startCleaningTimer(cleaningObj)
        //     }
        // } else
        if (currentTask) {
            this.updateCleaningTimer(currentTask.cleaning)
        } else {
            this.updateCleaningTimer()
        }
    }

    startCleaningTimer(cleaningObj) {
        this.updateCleaningTimer(cleaningObj)
        if (this.cleaningTimerHandle) {
            clearInterval(this.cleaningTimerHandle)
        }

        this.cleaningTimerHandle = setInterval(() => {
            this.updateCleaningTimer(cleaningObj)
        }, 1000)
    }

    stopCleaningTimer(render, showCleaningTimer = false, timerDisplay) {
        if (this.cleaningTimerHandle) {
            clearInterval(this.cleaningTimerHandle)
        }

        if (render) {
            let display
            if (timerDisplay) {
                display = timerDisplay
            } else {
                display = this.getZeroCleaningTimerDisplay()
            }

            this.setState({
                showCleaningTimer,
                cleaningTimerDisplay: display
            })
        }
    }

    getZeroCleaningTimerDisplay() {
        return {
            hours: '00',
            min: '00',
            sec: '00'
        }
    }

    updateCleaningTimer(cleaningObj) {
        let timerDisplay
        if (!cleaningObj) {
            timerDisplay = this.getZeroCleaningTimerDisplay()
        } else {
            timerDisplay = getTimerDisplay(cleaningObj)
        }

        this.setState({ showCleaningTimer: true, cleaningTimerDisplay: timerDisplay })
    }

    closeModal() {
        this.setState({ userModalIsOpen: false })
    }

    fetchLastCleaningTask(lastCleaningTaskKey) {
        tasksRepo
            .getTask(lastCleaningTaskKey)
            .then(lastTask => {
                this.setState({ lastCleaningTask: lastTask })
                this.setTimerOnUpdate(lastTask)
            })
            .catch(() => {
                console.log('(fetchLastCleaningTask = > did not find cleaning task)')
            })
    }

    async onCleaningStatusChange(cleaningStatus) {
        const area = this.state.area
        const task = this.state.task

        createActivity(
            firebase,
            area,
            this.props.currentUser,
            this.state.selectedDateNumber,
            'cleaning-status',
            'cleaningStatus',
            area.cleaningStatus,
            cleaningStatus
        )

        await analytics.logEvent(firebase, analytics.AREA_CLEANING_STATUS[0], analytics.AREA_CLEANING_STATUS[1], area.cleaningStatus)

        const areaObject = {}

        areaObject.lastStatusChange = {
            user: dataObjects.getMiniUserObject(this.props.currentUser),
            updated: moment().valueOf()
        }

        if (chelpers.isToday(this.state.selectedDateNumber)) {
            areaObject.cleaningStatus = cleaningStatus
            if (task && cleaningStatus === c.CLEANING_STATUS_CLEAN) {
                await taskData.completeTask(firebase, this.props.currentUser, task.key)
            }
        }

        await areaData.updateArea(firebase, area.key, areaObject, this.props.currentUser)

        const updatedArea = { ...area, cleaningStatus }

        this.setState({
            area: { ...area, cleaningStatus },
            selectedStatus: cleaningStatus
        })

        this.props.setCurrentArea && this.props.setCurrentArea(updatedArea)
        this.props.setCurrentTask && this.props.setCurrentTask(task)

        const activities = await areaData.getActivities(firebase, area.key, this.state.selectedDateNumber)
        this.setState({ activities: activities })
    }

    async onOccupancyPress(occupancy) {
        const area = this.state.area

        const areaObject = {}

        areaObject.lastStatusChange = {
            user: dataObjects.getMiniUserObject(this.props.currentUser),
            updated: moment().valueOf()
        }

        createActivity(
            firebase,
            area,
            this.props.currentUser,
            this.state.selectedDateNumber,
            'occupancy',
            'occupancy',
            area.occupancy,
            occupancy
        )

        await areaData.updateArea(firebase, area.key, areaObject, this.props.currentUser)

        const updatedArea = { ...area, occupancy }

        this.setState({
            area: updatedArea
        })

        setTimeout(() => {
            this.props.setCurrentArea && this.props.setCurrentArea(updatedArea)
        })

        const activities = await areaData.getActivities(firebase, area.key, this.state.selectedDateNumber)
        this.setState({ activities: activities })
    }

    renderCleaningStatusTitle() {
        return (
            <CleaningStatusSelect
                selectedStatus={this.state.selectedStatus}
                width={this.BOX_WIDTH}
                height={this.BOX_HEIGHT}
                onChange={item => {
                    this.onCleaningStatusChange(item.value)
                }}
            />
        )
    }

    renderTimer() {
        const DEBUG_BORDER = 0
        const ITEM_HEIGHT = 24
        const ITEM_WIDTH = 30

        const STOP_DISABLED = !!(this.state.task && !chelpers.isToday(this.state.task.startDate))

        const SHOW_HOURS = this.state.cleaningTimerDisplay && this.state.cleaningTimerDisplay.hours !== '00'

        let TIMER_FONT_SIZE = 20
        if (!SHOW_HOURS) {
            TIMER_FONT_SIZE = TIMER_FONT_SIZE + 1
        }
        const PLAY_ACTIVE = this.cleaningActionState === c.CLEANING_ACTION_PLAY
        const STOP_ACTIVE = this.cleaningActionState === c.CLEANING_ACTION_STOP
        const PAUSE_ACTIVE = this.cleaningActionState === c.CLEANING_ACTION_PAUSE

        let TIMER_COLOR = chelpers.pickCleaningStatusColor(this.state.area.cleaningStatus, this.state.area.occupancy)
        let FONT_WEIGHT = '700'
        let FONT_COLOR = TIMER_COLOR

        let TIMER_DESC = 'ACTIVE'

        if (STOP_ACTIVE) {
            TIMER_COLOR = iOSColors.midGray
            FONT_COLOR = TIMER_COLOR
            TIMER_DESC = 'Last duration'
        }
        if (PAUSE_ACTIVE) {
            TIMER_DESC = 'PAUSED'
        }
        const OPACITY = 0.9

        const ACTION_BUTTON_WIDTH = 45
        return (
            <View
                style={{
                    borderWidth: 0,
                    justifyContent: 'center'
                }}>
                <View
                    style={{
                        flexDirection: 'row'
                    }}>
                    <View
                        style={{
                            borderRadius: 6,
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: this.BOX_WIDTH,

                            height: this.BOX_HEIGHT,
                            borderWidth: 1,
                            borderStyle: 'dotted',
                            borderColor: TIMER_COLOR
                        }}>
                        {this.state.showCleaningTimer && this.state.cleaningTimerDisplay !== null && (
                            <View
                                style={{
                                    flexDirection: 'row',
                                    borderWidth: DEBUG_BORDER,
                                    width: 110,
                                    height: 35,
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                {SHOW_HOURS && (
                                    <span
                                        style={{
                                            width: ITEM_WIDTH,
                                            height: ITEM_HEIGHT,
                                            borderWidth: DEBUG_BORDER,
                                            textAlign: 'right',
                                            fontWeight: FONT_WEIGHT,
                                            color: FONT_COLOR,
                                            fontSize: TIMER_FONT_SIZE
                                        }}>
                                        {this.state.cleaningTimerDisplay.hours}
                                    </span>
                                )}
                                {SHOW_HOURS && (
                                    <span
                                        style={
                                            // iOSUIKit.subheadShort,
                                            {
                                                textAlign: 'center',
                                                alignItems: 'center',
                                                width: 10,
                                                height: ITEM_HEIGHT,
                                                borderWidth: DEBUG_BORDER,
                                                fontWeight: FONT_WEIGHT,
                                                color: FONT_COLOR,
                                                fontSize: TIMER_FONT_SIZE,
                                                marginHorizontal: 1
                                            }
                                        }>
                                        :
                                    </span>
                                )}
                                <span
                                    style={{
                                        width: ITEM_WIDTH,
                                        height: ITEM_HEIGHT,
                                        borderWidth: DEBUG_BORDER,
                                        textAlign: SHOW_HOURS ? 'center' : 'right',
                                        fontWeight: FONT_WEIGHT,
                                        color: FONT_COLOR,
                                        fontSize: TIMER_FONT_SIZE
                                    }}>
                                    {this.state.cleaningTimerDisplay.min}
                                </span>
                                <span
                                    style={{
                                        textAlign: 'center',
                                        alignItems: 'center',
                                        width: 10,
                                        height: ITEM_HEIGHT,
                                        borderWidth: DEBUG_BORDER,
                                        fontWeight: FONT_WEIGHT,
                                        color: FONT_COLOR,
                                        fontSize: TIMER_FONT_SIZE,
                                        marginHorizontal: 1
                                    }}>
                                    :
                                </span>
                                <span
                                    style={{
                                        width: ITEM_WIDTH,
                                        height: ITEM_HEIGHT,
                                        borderWidth: DEBUG_BORDER,
                                        textAlign: 'left',
                                        fontWeight: FONT_WEIGHT,
                                        color: FONT_COLOR,
                                        fontSize: TIMER_FONT_SIZE
                                    }}>
                                    {this.state.cleaningTimerDisplay.sec}
                                </span>
                            </View>
                        )}
                    </View>
                </View>
                <span
                    style={{
                        alignSelf: 'center',
                        color: TIMER_COLOR,
                        marginTop: 6,
                        fontSize: 12,
                        fontWeight: '500'
                    }}>
                    {TIMER_DESC}
                </span>
            </View>
        )
    }

    renderBookingInfo(IS_AREA_SYNCED, min_height) {
        const PADDING_BOTTOM = 5

        const FONT_SIZE = 15
        const MARGIN_BETWEEN_UNITS = 12
        const PADDING_LEFT = 6

        const FONT_WEIGHT = '400'

        let TITLE = 'Occupancy'

        if (IS_AREA_SYNCED) {
            TITLE = this.state.isFutureBooking ? 'Next booking' : 'Current booking'
        }

        const DEBUG_BORDER = 0
        return (
            <View
                style={{
                    // borderWidth: 1,
                    zIndex: 2,
                    borderColor: '#f4f5f6',
                    borderRadius: 6,
                    width: '95%',
                    paddingVertical: 24,
                    alignItems: 'center',
                    boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, .2)',
                    minHeight: min_height
                }}>
                <View>
                    <View
                        style={{
                            borderWidth: DEBUG_BORDER,
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: MARGIN_BETWEEN_UNITS
                        }}>
                        <View
                            style={{
                                width: 26,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderWidth: DEBUG_BORDER,
                                borderColor: 'red'
                            }}>
                            <Image
                                style={{ width: 17, height: 17.5 }}
                                source={chelpers.pickBookingNoteIcon(this.state.area.cleaningStatus, this.state.area.occupancy)}
                            />
                        </View>
                        <span
                            style={{
                                marginLeft: 4,
                                marginTop: 2,
                                fontWeight: '600',
                                color: iOSColors.black,
                                fontSize: this.UNIT_HEADER_FONT_SIZE
                            }}>
                            {TITLE}
                        </span>
                    </View>
                    <View
                        style={{
                            borderColor: 'red',
                            borderWidth: 0,
                            marginBottom: MARGIN_BETWEEN_UNITS + 6
                        }}>
                        <OccupancySelect
                            width={this.BOX_WIDTH}
                            height={this.BOX_HEIGHT}
                            isAreaSynced={this.state.area.synced}
                            selectedDateNumber={this.state.selectedDateNumber}
                            occupancy={this.state.area ? this.state.area.occupancy : null}
                            onChange={item => {
                                this.setState({
                                    occupancy: item.value,
                                    occupancyState: item.label,
                                    occupancyText: 'Unknown'
                                })

                                this.onOccupancyPress(item.value)
                            }}
                        />
                    </View>
                    {IS_AREA_SYNCED && (
                        <View>
                            {this.state.guestName && (
                                <View
                                    style={{
                                        paddingLeft: PADDING_LEFT,
                                        justifyContent: 'center',
                                        borderWidth: DEBUG_BORDER,
                                        borderColor: 'red',
                                        paddingBottom: PADDING_BOTTOM
                                    }}>
                                    <span
                                        style={{
                                            fontWeight: '500',
                                            fontSize: FONT_SIZE, // 18
                                            color: iOSColors.black
                                        }}>
                                        {this.state.guestName}
                                    </span>
                                </View>
                            )}
                            {this.state.guestCount && (
                                <View
                                    style={{
                                        paddingLeft: PADDING_LEFT,
                                        justifyContent: 'center',
                                        borderWidth: DEBUG_BORDER,
                                        borderColor: 'red',
                                        paddingBottom: PADDING_BOTTOM
                                    }}>
                                    <span
                                        style={{
                                            fontWeight: FONT_WEIGHT,
                                            fontSize: FONT_SIZE, // 18
                                            color: iOSColors.gray
                                        }}>
                                        {this.state.guestCount}
                                    </span>
                                </View>
                            )}

                            {this.state.occupancyText && (
                                <View
                                    style={{
                                        paddingLeft: PADDING_LEFT,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        borderColor: 'yellow',
                                        borderWidth: DEBUG_BORDER,
                                        paddingBottom: PADDING_BOTTOM
                                    }}>
                                    <span
                                        style={{
                                            fontWeight: FONT_WEIGHT,
                                            color: iOSColors.gray,
                                            fontSize: FONT_SIZE
                                        }}>
                                        {this.state.occupancyText}
                                    </span>
                                </View>
                            )}

                            {this.state.bedSetup && (
                                <View
                                    style={{
                                        paddingLeft: PADDING_LEFT,
                                        justifyContent: 'space-between',
                                        flexDirection: 'row',
                                        alignItems: 'center',

                                        borderColor: 'yellow',
                                        borderWidth: DEBUG_BORDER
                                    }}>
                                    <span
                                        style={{
                                            fontWeight: FONT_WEIGHT,
                                            color: iOSColors.gray,
                                            fontSize: FONT_SIZE
                                        }}>
                                        {this.state.bedSetup}
                                    </span>
                                </View>
                            )}
                        </View>
                    )}
                </View>
            </View>
        )
    }

    renderAssignedTo(MARGIN_VERTICAL) {
        const task = this.state.task
        const assignedTo = task && task.assignedTo ? task.assignedTo : undefined
        const isAllowed = userIsAllowed(
            'assign-housekeeping-un-assign',
            this.props.currentUser,
            this.props.currentOrganization,
            this.props.currentSubscription
        )

        if (!assignedTo) {
            return null
        }

        return (
            <View
                style={{
                    marginTop: MARGIN_VERTICAL,
                    borderColor: 'f#f4f5f6',
                    borderRadius: 6,
                    width: '95%',
                    paddingVertical: 24,
                    boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, .2)'
                }}>
                <View style={{ borderWidth: 0, paddingHorizontal: 24 }}>
                    {assignedTo && assignedTo.length > 0 && (
                        <AssigneesView
                            maxCountToDisplay={6}
                            unassignDisabled={this.props.unassignDisabled || !isAllowed}
                            assignedTo={assignedTo}
                            currentUser={this.props.currentUser}
                            onUnassignPress={() => {
                                this.setState({ task: null })
                                if (this.props.selectedDateNumber === this.state.selectedDateNumber) {
                                    this.props.onUnassignPress()
                                    return
                                }
                                this.unassignForNotToday()
                            }}
                        />
                    )}
                </View>
            </View>
        )
    }

    renderHouskeepingStatus(min_height) {
        const MARGIN_BETWEEN_UNITS = 12
        return (
            <View
                style={{
                    zIndex: 2,
                    borderColor: '#f4f5f6',
                    borderRadius: 6,
                    width: '95%',
                    paddingVertical: 24,
                    alignItems: 'center',
                    boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, .2)',
                    height: min_height
                }}>
                <View>
                    <View
                        style={{
                            borderWidth: 0,
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: MARGIN_BETWEEN_UNITS
                        }}>
                        <View
                            style={{
                                width: 26,
                                flexDirection: 'row',
                                marginLeft: 4,
                                alignItems: 'center',
                                // justifyContent: 'center',
                                borderWidth: 0,
                                borderColor: 'red'
                            }}>
                            <Image
                                style={{ width: 20, height: 16.59 }}
                                source={hkhelpers.pickHouskeepingStatusIcon(this.state.area.cleaningStatus, this.state.area.occupancy)}
                            />
                        </View>
                        <span
                            style={{
                                marginTop: 2,
                                fontWeight: '600',
                                color: iOSColors.black,
                                fontSize: this.UNIT_HEADER_FONT_SIZE
                            }}>
                            Housekeeping status
                        </span>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', borderWidth: 0 }}>
                        {this.renderCleaningStatusTitle(MARGIN_BETWEEN_UNITS)}
                    </View>
                    <View
                        style={{
                            marginTop: MARGIN_BETWEEN_UNITS + 6,
                            borderWidth: 0,
                            width: this.BOX_WIDTH
                        }}>
                        {this.renderTimer()}
                    </View>
                </View>
            </View>
        )
    }

    renderLastHouskeeping() {
        const task = this.state.lastCleaningTask
        const FONT_SIZE = 15
        const DEBUG_BORDER = 0

        const PADDING_LEFT = 6

        const dateFormat = {
            sameDay: '[Today at ] H:mm',
            lastDay: '[Yesterday at] H:mm',
            lastWeek: '[Last] dddd [at] H:mm',
            sameElse: 'dddd, MMM D YYYY [at] H:mm'
        }

        const MARGIN_BETWEEN_UNITS = 12

        const MARGIN_BETWEEN_LINES = 4
        const DESCRIPTION_FONT_SIZE = 14

        let durationText = ''

        if (task && task.cleaning && task.cleaning.end) {
            durationText = durationToText(task.cleaning)
        }

        return (
            <View
                style={{
                    borderColor: 'red',
                    borderWidth: 0
                }}>
                <span
                    style={{
                        fontWeight: '600',
                        color: iOSColors.black,
                        fontSize: this.UNIT_HEADER_FONT_SIZE,
                        // marginBottom: 12,
                        overflow: 'visible'
                    }}>
                    Last housekeeping
                </span>

                <View>
                    <View
                        style={{
                            marginTop: chelpers.isToday(this.state.selectedDateNumber) ? 8 : 0,
                            flexDirection: 'column',

                            borderWidth: DEBUG_BORDER,
                            borderColor: 'red',
                            marginBottom: MARGIN_BETWEEN_UNITS
                        }}>
                        <span
                            style={{
                                fontSize: DESCRIPTION_FONT_SIZE,
                                color: iOSColors.midGray,
                                marginBottom: MARGIN_BETWEEN_LINES
                            }}>
                            Finished
                        </span>

                        <span
                            style={{
                                fontSize: FONT_SIZE,
                                color: iOSColors.gray
                            }}>
                            {moment(task.cleaning.end).calendar(null, dateFormat)}
                        </span>
                    </View>
                    {task.assignedTo && (
                        <View
                            style={{
                                flexDirection: 'column',
                                borderWidth: DEBUG_BORDER,
                                borderColor: 'red',
                                marginBottom: MARGIN_BETWEEN_UNITS
                            }}>
                            <span
                                style={{
                                    fontSize: DESCRIPTION_FONT_SIZE,
                                    color: iOSColors.midGray,
                                    marginBottom: MARGIN_BETWEEN_LINES
                                }}>
                                Done by
                            </span>
                            <Assignees assignees={task.assignedTo} currentUser={this.props.currentUser} />
                        </View>
                    )}
                    {durationText.length > 0 && (
                        <View
                            style={{
                                flexDirection: 'column',

                                borderColor: 'yellow',
                                borderWidth: DEBUG_BORDER
                            }}>
                            <span
                                style={{
                                    color: iOSColors.midGray,
                                    fontSize: DESCRIPTION_FONT_SIZE,
                                    marginBottom: MARGIN_BETWEEN_LINES
                                }}>
                                Duration
                            </span>
                            <span
                                style={{
                                    color: iOSColors.gray,
                                    fontSize: FONT_SIZE
                                }}>
                                {durationText}
                            </span>
                        </View>
                    )}
                </View>

                {task.bedSetup && (
                    <View
                        style={{
                            paddingLeft: PADDING_LEFT,
                            flexDirection: 'column',
                            borderColor: 'yellow',
                            borderWidth: DEBUG_BORDER,
                            marginBottom: MARGIN_BETWEEN_UNITS
                        }}>
                        <span
                            style={{
                                color: iOSColors.midGray,
                                fontSize: DESCRIPTION_FONT_SIZE,
                                marginBottom: MARGIN_BETWEEN_LINES
                            }}>
                            Last bed setup
                        </span>
                        <span
                            style={{
                                color: iOSColors.gray,
                                fontSize: FONT_SIZE
                            }}>
                            {task.bedSetup}
                        </span>
                    </View>
                )}
            </View>
        )
    }

    renderBookingNotes() {
        const DEBUG_BORDER = 0

        return (
            <View
                style={{
                    display: 'flex',
                    flex: 1
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',

                        borderWidth: DEBUG_BORDER,
                        borderColor: 'red',
                        marginBottom: 6
                    }}>
                    <View
                        style={{
                            width: 26,
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderWidth: DEBUG_BORDER,
                            borderColor: 'red'
                        }}>
                        <Image
                            style={{ width: 17, height: 17.49 }}
                            source={require('@shared/img/cleaning-status/booking-note-black.svg')}
                        />
                    </View>
                    <span
                        style={{
                            marginTop: 2,
                            marginLeft: 4,
                            fontWeight: '600',
                            color: iOSColors.black,
                            fontSize: this.UNIT_HEADER_FONT_SIZE
                        }}>
                        Booking notes
                    </span>
                </View>
                <View style={{}}>
                    <span
                        style={{
                            paddingHorizontal: 32,
                            fontSize: 14,
                            color: iOSColors.gray,
                            whiteSpace: 'pre-line'
                        }}>
                        {this.state.bookingNotes}
                    </span>
                </View>
            </View>
        )
    }

    renderRule() {
        const DEBUG_BORDER = 0
        const rule = this.state.area.activeRule
        const area = this.state.area
        const description = rule.description ? rule.description : 'This rule has no description'
        const cleaningActionIcon = chelpers.pickCleaningActionIcon(
            area.cleaningStatus,
            area.occupancy,
            area.cleaningFrequency,
            area.rules,
            true
        )

        return (
            <View
                style={{
                    display: 'flex',
                    flex: 1
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',

                        borderWidth: DEBUG_BORDER,
                        borderColor: 'red',
                        marginBottom: 6
                    }}>
                    {cleaningActionIcon && (
                        <View
                            style={{
                                width: 26,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderWidth: DEBUG_BORDER,
                                borderColor: 'red'
                            }}>
                            <Image
                                style={{ width: cleaningActionIcon.width * 1.2, height: cleaningActionIcon.height * 1.2 }}
                                source={cleaningActionIcon.source}
                            />
                        </View>
                    )}

                    <span
                        style={{
                            marginTop: 2,
                            marginLeft: cleaningActionIcon ? 4 : 0,
                            fontWeight: '600',
                            color: iOSColors.black,
                            fontSize: this.UNIT_HEADER_FONT_SIZE
                        }}>
                        {rule.name}
                    </span>
                </View>
                <View>
                    <span
                        style={{
                            paddingHorizontal: 32,
                            fontSize: 14,
                            color: iOSColors.gray,
                            whiteSpace: 'pre-line'
                        }}>
                        {description}
                    </span>
                </View>
            </View>
        )
    }

    renderChecklist() {
        return (
            <View
                style={{
                    display: 'flex',
                    flex: 1
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderColor: 'red',
                        marginBottom: 6
                    }}>
                    <span
                        style={{
                            marginTop: 2,
                            fontWeight: '600',
                            color: iOSColors.black,
                            fontSize: this.UNIT_HEADER_FONT_SIZE
                        }}>
                        Checklist
                    </span>
                </View>
                <View>
                    <div>
                        {this.state.task.checklist.map((t, i) => {
                            return (
                                <div key={t.name + i} className="d-flex my-5">
                                    <div>
                                        <Checkbox isSelected={t.checked} disabled={true} />
                                    </div>
                                    <div style={{ color: iOSColors.gray }} className="w-75 font-size-lg font-weight-bold">
                                        {t.name}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </View>
            </View>
        )
    }

    sectionWrapper(title, children) {}

    async onCleaningPriorityPress(area, priority) {
        let nextPriority = null
        let task = this.state.task

        nextPriority = priority !== true

        const changeObj = {
            field: c.ACTIVITY_TYPE_CLEANING_PRIORITY,
            before: priority,
            after: nextPriority
        }

        await areaData.logActivity(
            firebase,
            this.props.currentUser,
            area.key,
            c.ACTIVITY_TYPE_CLEANING_PRIORITY,
            this.state.selectedDateNumber,
            changeObj
        )

        this.setState({ cleaningPriority: nextPriority })

        if (task && task.key) {
            await taskData.setTaskPriority(firebase, this.props.currentUser, task.key, { priority: nextPriority })
        }

        const activities = await areaData.getActivities(firebase, area.key, this.state.selectedDateNumber)
        this.setState({ activities: activities })
    }

    renderPriorityButton() {
        let imgName = null
        let tooltip
        if (this.state.cleaningPriority === true) {
            imgName = require('@shared/img/priority-high-white-bkg.svg')
            tooltip = 'Normal priority'
        } else {
            imgName = require('@shared/img/priority-low-white-bkg.svg')
            tooltip = 'High priority'
        }
        return (
            <HoverableImageButton
                dimension={uc.BUTTON_DIM}
                hoverOpacity={uc.HOVER_OPACITY}
                imageSource={imgName}
                onPress={() => this.onCleaningPriorityPress(this.state.area, this.state.cleaningPriority)}
                tooltipText={tooltip}
            />
        )
    }

    shouldRenderDailyCommentIcon(value) {
        if (value) {
            this.setState({ renderDailyCommentIcon: true })
        } else {
            this.setState({ renderDailyCommentIcon: false })
        }
    }

    aIssueOpenModal() {
        this.setState({ aIssueModalIsOpen: true })
    }

    aIssueCloseModal() {
        this.setState({ aIssueModalIsOpen: false })
    }

    onPressIssueRow(issue) {
        this.setState({
            currentIssue: issue
        })
        this.aIssueOpenModal()
    }

    closeUpgradeMessage() {
        this.setState({ showUpgradeMessage: false })
    }

    openUpgradeMessage(notAccessibleFeature, availableUpgrades) {
        this.setState({
            showUpgradeMessage: true,
            availableUpgrades: availableUpgrades,
            notAccessibleFeature: notAccessibleFeature
        })
    }

    async onAssignPress(users) {
        const task = this.state.task
        if (!task) {
            this.setState({ task: {} })
        }

        const updatedTask = { ...task, assignedTo: users.map(a => dataObjects.getMiniUserObject(a)), assignedToKeys: users.map(a => a.key) }
        const assignedTo = HousekeepingModal.createAssignedToObj(this.props.task)

        this.setState({ userModalOpen: false, assignedTo: assignedTo, task: updatedTask })

        if (this.props.selectedDateNumber !== this.state.selectedDateNumber) {
            await this.assignForNotToday(users)
            return
        }

        if (this.props.onAssignPress) {
            this.props.onAssignPress(users, this.state.selectedDateNumber, updatedTask)
        } else {
            await assignUsers(users, updatedTask, this.props.currentUser, this.context.modalDaySelected || this.state.selectedDateNumber)
            this.props.setCurrentTask(updatedTask)
        }
    }

    async assignForNotToday(users) {
        const { area, selectedDateNumber } = this.state

        const logAssigning = async () => {
            const changeObj = {
                before: 'assigned',
                after: users.map(user => user.name).join(', ')
            }
            await areaData.logActivity(firebase, this.props.currentUser, area.key, 'assignment', selectedDateNumber, changeObj)
            const activities = await areaData.getActivities(firebase, area.key, selectedDateNumber)
            this.setState({ activities })
        }

        if (area.task && area.task.key !== 'virtual-task') {
            const taskUpdateObject = {
                assignedTo: users.map(a => dataObjects.getMiniUserObject(a)),
                priority: area.task.priority,
                name: area.ruleName
            }

            const updatedTask = await taskData.setTaskUpdate(firebase, this.props.currentUser, area.task.key, taskUpdateObject)

            await logAssigning()
            this.props.setCurrentTask({ ...area.task, ...updatedTask })

            return
        }

        const { key } = await taskData.createTask(firebase, area, this.props.currentUser, c.TASK_TYPE_HOUSEKEEPING, {
            assignedTo: users,
            name: area.ruleName ?? this.props.cleaningTaskName,
            startDate: selectedDateNumber
        })

        const task = await tasksRepo.getTask(key)

        await logAssigning()
        this.props.setCurrentTask(task)
    }

    async unassignForNotToday() {
        const { area, task, selectedDateNumber } = this.state

        const changeObj = {
            before: 'unassigned'
        }

        await taskData.deleteTask(firebase, task.key, this.props.currentUser)
        await areaData.logActivity(firebase, this.props.currentUser, area.key, 'assignment', selectedDateNumber, changeObj)
        this.props.setCurrentTask(null)
    }

    render() {
        this.BOX_WIDTH = 240
        this.BOX_HEIGHT = 40

        const aissue_style = JSON.parse(JSON.stringify(this.props.style))
        aissue_style.content.maxHeight = '70vh'
        aissue_style.content.height = '70vh'

        aissue_style.content.width = '70%'

        if (!this.state.area) {
            return null
        }

        const DATA_IS_READY = this.state.dataIsReady
        const IS_AREA_SYNCED = this.state.area.synced
        const SHOW_BOOKING_NOTES =
            this.state.bookingNotes !== undefined && this.state.bookingNotes !== null && this.state.bookingNotes !== ''

        const HAS_CLEANING_OBJ = this.state.lastCleaningTask && this.state.lastCleaningTask.cleaning
        const HAS_ACTIVITY = this.state.activities && this.state.activities.length > 0
        const HAS_EXTRAS = this.extras && this.extras.length > 0

        const SHOW_ISSUES = this.state.issues && this.state.issues.length > 0
        const SHOW_RULE = this.state.area.activeRule
        const HAS_TASK_CHECKLIST = this.state.task && this.state.task.checklist && this.state.task.checklist.length > 0

        const IS_OPT_IN = this.props.currentOrganization.allowOptIn || this.props.currentOrganization.allowOptOut

        const DEBUG_BORDER = 0
        const min_title_box_height = 250

        const renderExtras = () => <ExtraServicesInfo extras={this.extras} />
        const sections = [this.renderDailyComment]
        if (HAS_EXTRAS) {
            sections.push(renderExtras)
        }
        sections.push(this.renderAreaNotes)
        if (SHOW_BOOKING_NOTES) {
            sections.push(this.renderBookingNotes)
        }
        if (IS_OPT_IN && IS_AREA_SYNCED && this.state.leadBooking) {
            sections.unshift(this.renderCleaningSchedule)
        }
        if (SHOW_RULE) {
            sections.push(this.renderRule)
        }
        if (SHOW_RULE && HAS_TASK_CHECKLIST) {
            sections.push(this.renderChecklist)
        }
        if (HAS_CLEANING_OBJ) {
            sections.push(this.renderLastHouskeeping)
        }
        if (SHOW_ISSUES) {
            sections.push(this.renderIssueList)
        }
        if (HAS_ACTIVITY) {
            sections.push(this.renderActivities)
        }

        return (
            <View
                style={{
                    flexDirection: 'column',
                    minHeight: this.props.height,
                    padding: 0,

                    marginBottom: 40
                }}>
                <Modal
                    isOpen={this.state.showUpgradeMessage}
                    style={styles.upgradeMessageStyle}
                    contentLabel="UpgradeMessages"
                    onRequestClose={this.closeUpgradeMessage}>
                    <UpgradeMessageContent
                        onClose={this.closeUpgradeMessage}
                        notAccessibleFeature={this.state.notAccessibleFeature}
                        availableUpgrades={this.state.availableUpgrades}
                        showCloseButton={true}
                    />
                </Modal>
                <Modal
                    isOpen={this.state.userModalOpen}
                    onRequestClose={() => {
                        this.setState({ userModalOpen: false })
                    }}
                    style={styles.userListStyle}
                    contentLabel="User">
                    <UsersList
                        areas={[this.state.area]}
                        task={{ ...this.state.task }}
                        closeModal={() => {
                            this.setState({ userModalOpen: false })
                        }}
                        currentUser={this.props.currentUser}
                        onAssignPress={this.onAssignPress}
                    />
                </Modal>
                <Modal
                    isOpen={this.state.aIssueModalIsOpen}
                    onRequestClose={this.aIssueCloseModal}
                    style={aissue_style}
                    contentLabel="Open issue">
                    <AIssue
                        currentUser={this.props.currentUser}
                        issue={this.state.currentIssue}
                        currentOrganization={this.props.currentOrganization}
                        closeModal={this.aIssueCloseModal}
                        respWidth={aissue_style.content.width}
                        respHeight={aissue_style.content.height}
                    />
                </Modal>
                {!DATA_IS_READY && (
                    <View
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <ActivityIndicator size="large" color={brand.getBrand().navBarColor} />
                    </View>
                )}
                <Notifications options={{ animationDuration: 1000, top: '45%' }} />
                <View
                    style={{
                        zIndex: 99,
                        backgroundColor: colors.white,
                        position: 'sticky',
                        top: 0,
                        left: 0
                    }}>
                    <CloseModalButton close={this.props.closeModal} isWhite={true} />
                    {this.renderHeader(DATA_IS_READY, uc.MODAL_SECTION_VERTICAL_SPACE - 8, uc.MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE)}
                </View>

                <View style={{ backgroundColor: colors.white }}>
                    <View
                        style={{
                            borderColor: 'red',
                            borderWidth: 0,
                            flexDirection: 'row',
                            paddingHorizontal: uc.MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE,
                            marginTop: uc.MODAL_SECTION_VERTICAL_SPACE + 6,
                            marginBottom: uc.MODAL_SECTION_VERTICAL_SPACE + 6
                        }}>
                        <View style={{ borderColor: 'red', borderWidth: 0, width: '50%' }}>
                            {DATA_IS_READY && this.renderBookingInfo(IS_AREA_SYNCED, min_title_box_height)}
                            {DATA_IS_READY && this.renderAssignedTo(uc.MODAL_SECTION_VERTICAL_SPACE)}
                        </View>
                        <View style={{ borderColor: 'red', borderWidth: 0, width: '50%', zIndex: 100, alignItems: 'flex-end' }}>
                            {DATA_IS_READY && this.renderHouskeepingStatus(min_title_box_height)}
                        </View>
                    </View>

                    {DATA_IS_READY &&
                        sections.map((fn, i) => {
                            return (
                                <View
                                    key={i}
                                    style={{
                                        marginTop: 0,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        paddingHorizontal: uc.MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE,
                                        paddingTop: uc.MODAL_SECTION_VERTICAL_SPACE,
                                        paddingBottom: 24,
                                        backgroundColor: i % 2 ? colors.white : colors.gentle_gray_spectaflow
                                    }}>
                                    {fn()}
                                </View>
                            )
                        })}

                    {DATA_IS_READY && this.props.currentUser.authSuper && (
                        <View
                            style={{
                                borderColor: 'red',
                                borderWidth: DEBUG_BORDER,
                                flexDirection: 'row',
                                paddingHorizontal: uc.MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE,
                                marginBottom: uc.MODAL_SECTION_VERTICAL_SPACE
                            }}>
                            {this.renderDeveloperSection()}
                        </View>
                    )}
                </View>
            </View>
        )
    }

    renderDailyComment() {
        return (
            <View style={{ marginBottom: 0, borderWidth: 0, width: '100%' }}>
                <View
                    style={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        borderWidth: 0,
                        marginBottom: 12
                    }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Image
                            style={{ height: 26, width: 26 }}
                            source={require('@shared/img/cleaning-status/daily-comment-filter-off.svg')}
                        />

                        <span
                            style={{
                                marginLeft: 6,
                                fontWeight: '600',
                                color: iOSColors.black,
                                fontSize: this.UNIT_HEADER_FONT_SIZE
                            }}>
                            Daily comment
                            <span
                                style={{
                                    marginLeft: 6,
                                    fontWeight: '500',
                                    color: iOSColors.midGray,
                                    fontSize: this.UNIT_HEADER_FONT_SIZE - 3
                                }}>
                                displays on {moment(this.state.selectedDateNumber).format('ddd, MMM D YYYY')}
                            </span>
                        </span>
                    </View>
                    <SubscriptionCheck
                        featureId="daily-comments"
                        onAvailable={() => null}
                        onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                            this.dailyCommentsFeatureNotAvailable = true
                            return (
                                <FeatureLockedSignal
                                    showLock
                                    isActive
                                    onClick={() => {
                                        this.openUpgradeMessage(notAccessibleFeature, availableUpgrades)
                                    }}
                                />
                            )
                        }}
                    />
                </View>
                <DailyCommentTextInput
                    disabled={this.dailyCommentsFeatureNotAvailable}
                    area={this.state.area}
                    currentUser={this.props.currentUser}
                    selectedDateNumber={this.state.selectedDateNumber}
                    shouldRenderDailyCommentIcon={this.shouldRenderDailyCommentIcon}
                />
            </View>
        )
    }

    renderAreaNotes() {
        return (
            <View style={{ marginBottom: 0, borderWidth: 0, width: '100%' }}>
                <View
                    style={{
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderWidth: 0,
                        marginBottom: 12
                    }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Image
                            style={{ width: 18, height: 23 }}
                            source={chelpers.pickAreaNoteIcon(this.state.area.cleaningStatus, this.state.area.occupancy)}
                        />
                        <span
                            style={{
                                marginLeft: 6,
                                fontWeight: '600',
                                color: iOSColors.black,
                                fontSize: this.UNIT_HEADER_FONT_SIZE
                            }}>
                            Unit note
                            <span
                                style={{
                                    marginLeft: 6,
                                    fontWeight: '600',
                                    color: iOSColors.midGray,
                                    fontSize: this.UNIT_HEADER_FONT_SIZE - 3
                                }}>
                                is permanent
                            </span>
                        </span>
                    </View>
                    <SubscriptionCheck
                        featureId="unit-notes"
                        onAvailable={() => null}
                        onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                            this.dailyCommentsFeatureNotAvailable = true
                            return (
                                <FeatureLockedSignal
                                    showLock
                                    isActive
                                    onClick={() => {
                                        this.openUpgradeMessage(notAccessibleFeature, availableUpgrades)
                                    }}
                                />
                            )
                        }}
                    />
                </View>
                <AreaNoteTextInput
                    disabled={this.dailyCommentsFeatureNotAvailable}
                    area={this.state.area}
                    currentUser={this.props.currentUser}
                    selectedDateNumber={this.state.selectedDateNumber}
                    shouldRenderDailyCommentIcon={this.shouldRenderDailyCommentIcon}
                />
            </View>
        )
    }

    async deleteDueDate() {
        const area = this.state.area
        const task = this.state.task

        const changeObj = {
            before: 'unassigned'
        }
        await areaData.logActivity(
            firebase,
            this.props.currentUser,
            area.key,
            c.ACTIVITY_TYPE_DELETE_DUE_DATE,
            this.state.selectedDateNumber,
            changeObj
        )
        await taskData.deleteTask(firebase, task.key, this.props.currentUser)

        this.setState({ task: null, dueDateNumber: null })
    }

    renderHeader(DATA_IS_READY) {
        let headerBackgroundColor = !DATA_IS_READY
            ? iOSColors.black
            : chelpers.pickCleaningStatusColor(this.state.area.cleaningStatus, this.state.area.occupancy)
        const cleaningActionIcon = chelpers.pickCleaningActionIcon(
            this.state.area.cleaningStatus,
            this.state.area.occupancy,
            this.state.area.cleaningFrequency,
            this.state.area.rules,
            false
        )
        const cleaningStatusIcon = chelpers.pickCleaningStatusIcon(this.state.area.cleaningStatus, false)
        const checkinIcon = chelpers.pickCheckinIcon(this.state.area.cleaningStatus, this.state.area.occupancy, false)
        const guestsIcon = chelpers.pickPeopleIcon(
            this.state.area.cleaningStatus,
            this.state.area.occupancy,
            this.state.area.guestCheckedIn,
            false
        )
        const pauseIcon = chelpers.pickPauseIcon(this.state.area.cleaningStatus, false)
        const inspectionIcon = chelpers.pickInspectionIcon(this.state.area.cleaningStatus, false)

        const ruleNumber =
            this.state.area.activeRule && this.state.area.activeRule.customChar ? this.state.area.activeRule.customChar : null

        const ICON_MARGIN_LEFT = 20

        const TOP_ROW_HEIGHT = 40
        const ICON_SCALE = 1.5

        const IS_TODAY = chelpers.isToday(this.state.selectedDateNumber)
        const DEBUG_BORDER = 0
        const isCheckotCheckinDay = this.props.usingProjection
            ? false
            : this.props.area.bookings.length > 1 &&
              chelpers.isToday(this.props.area.bookings[0].checkoutDate) &&
              chelpers.isToday(this.props.area.bookings[1].checkinDate)

        const SHOW_QR_BUTTON = isCheckotCheckinDay || this.props.area.bookings[0]

        const bottomIcons = [
            ...(inspectionIcon
                ? [
                      <BoxIcon
                          key={'inspection-icon'}
                          source={inspectionIcon.source}
                          width={inspectionIcon.width}
                          height={inspectionIcon.height}
                          iconScale={ICON_SCALE}
                      />
                  ]
                : []),
            ...(pauseIcon
                ? [
                      <BoxIcon
                          key={'pause-icon'}
                          source={pauseIcon.source}
                          width={pauseIcon.width}
                          height={pauseIcon.height}
                          iconScale={ICON_SCALE}
                      />
                  ]
                : []),
            ...(this.state.renderDailyCommentIcon
                ? [
                      <BoxIcon
                          key={'daily-comment-icon'}
                          source={require('@shared/img/cleaning-status/daily-comment-white.png')}
                          width={17}
                          height={17}
                          iconScale={ICON_SCALE}
                      />
                  ]
                : []),
            ...(checkinIcon
                ? [
                      <BoxIcon
                          key={'checkin-icon'}
                          source={checkinIcon.source}
                          width={checkinIcon.width}
                          height={checkinIcon.height}
                          iconScale={ICON_SCALE}
                      />
                  ]
                : []),
            ...(guestsIcon
                ? [
                      <BoxIcon
                          key={'guests-icon'}
                          source={guestsIcon.source}
                          width={guestsIcon.width}
                          height={guestsIcon.height}
                          iconScale={ICON_SCALE}
                      />
                  ]
                : []),
            ...(cleaningActionIcon
                ? [
                      <BoxIcon
                          key={'cleaning-action-icon'}
                          source={cleaningActionIcon.source}
                          height={cleaningActionIcon.height}
                          width={cleaningActionIcon.width}
                          iconScale={ICON_SCALE}
                      />
                  ]
                : []),
            ...(cleaningStatusIcon
                ? [
                      <BoxIcon
                          key={'cleaning-status-icon'}
                          source={cleaningStatusIcon.source}
                          width={cleaningStatusIcon.width}
                          height={cleaningStatusIcon.height}
                          iconScale={ICON_SCALE}
                      />
                  ]
                : []),
            ...(ruleNumber ? [<CircleNumber key={'custom-icon'} char={ruleNumber} size={ICON_SCALE ? ICON_SCALE * 20 : 20} />] : [])
        ]

        return (
            <View
                style={{
                    backgroundColor: headerBackgroundColor,
                    opacity: this.state.dataIsReady ? 1 : 0.1,
                    borderWidth: 0,
                    borderColor: 'blue',

                    paddingVertical: uc.MODAL_TOP_TO_BUTTONS_BAR_VERTICAL_SPACE,
                    paddingHorizontal: uc.MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE
                }}>
                <View
                    style={{
                        zIndex: 99,
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderWidth: DEBUG_BORDER,
                        borderColor: 'white',
                        flexDirection: 'row',
                        height: 40
                    }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            columnGap: '25%',
                            borderWidth: DEBUG_BORDER,
                            borderColor: 'white',
                            width: '30%',
                            height: TOP_ROW_HEIGHT,
                            marginBottom: 0
                        }}>
                        {IS_TODAY && this.renderPriorityButton(uc.BUTTON_DIM)}

                        {SHOW_QR_BUTTON && (
                            <GuestQrButton
                                bookingId={
                                    isCheckotCheckinDay ? this.props.area.bookings[1].bookingId : this.props.area.bookings[0].bookingId
                                }
                            />
                        )}

                        <SubscriptionCheck
                            featureId="assign-housekeeping"
                            permissionId="assign-housekeeping-assign"
                            onAvailable={() => (
                                <HoverableImageButton
                                    dimension={uc.BUTTON_DIM}
                                    hoverOpacity={uc.HOVER_OPACITY}
                                    imageSource={require('../img/assign-task-button-white-bkgr.svg')}
                                    onPress={() => {
                                        this.setState({ userModalOpen: true })
                                    }}
                                    tooltipText={'Assign'}
                                />
                            )}
                            onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                                return (
                                    <HoverableImageButton
                                        dimension={uc.BUTTON_DIM}
                                        hoverOpacity={uc.HOVER_OPACITY}
                                        imageSource={require('../img/assign-task-button-white-bkgr.svg')}
                                        onPress={() => {
                                            this.openUpgradeMessage(notAccessibleFeature, availableUpgrades)
                                        }}
                                        tooltipText={'Assign'}
                                    />
                                )
                            }}
                        />
                    </View>

                    <View
                        style={{
                            borderWidth: DEBUG_BORDER,
                            width: '40%',
                            height: TOP_ROW_HEIGHT,
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row'
                        }}>
                        <View style={{ borderWidth: 0, display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                            <SubscriptionCheck
                                featureId="browse-history"
                                onAvailable={() => (
                                    <DatePicker
                                        isWhite
                                        hasTodayButton
                                        selectedDateNumber={this.selectedDate.valueOf()}
                                        onDateChange={this.onDateChange}
                                    />
                                )}
                                onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                                    return (
                                        <DatePicker
                                            isWhite
                                            hasTodayButton
                                            selectedDateNumber={this.selectedDate.valueOf()}
                                            onDateChange={() => {
                                                this.openUpgradeMessage(notAccessibleFeature, availableUpgrades)
                                            }}
                                        />
                                    )
                                }}
                            />
                        </View>
                    </View>
                    <View style={{ width: '30%', flexDirection: 'row', borderWidth: DEBUG_BORDER, justifyContent: 'flex-end' }} />
                </View>

                <View style={{ flexDirection: 'column', borderWidth: 0, borderColor: 'white', width: '100%' }}>
                    <View
                        style={{
                            width: '100%',
                            borderWidth: 0,
                            borderColor: 'white',
                            justifyContent: 'space-between',
                            marginTop: 12
                        }}>
                        <AreaBredCrumbs
                            spaceBetween={9}
                            area={this.state.area}
                            fontSize={19}
                            fontWeight={'500'}
                            fontColor={iOSColors.white}
                            arrowColor={iOSColors.white}
                        />
                    </View>

                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',

                            borderWidth: 0,
                            marginTop: 12,
                            height: 28
                        }}>
                        <View>
                            {this.state.dueDateNumber && (
                                <>
                                    <SubscriptionCheck
                                        featureId="assign-housekeeping"
                                        permissionId="assign-housekeeping-assign"
                                        onAvailable={() => (
                                            <DueDateButton
                                                dueDate={this.state.dueDateNumber}
                                                inverse={true}
                                                onClick={this.deleteDueDate}
                                                selectedDate={this.state.selectedDateNumber}
                                            />
                                        )}
                                        onNotAvailable={() => {
                                            return null
                                        }}
                                    />
                                </>
                            )}
                        </View>
                        {DATA_IS_READY && (
                            <View
                                style={{
                                    alignItems: 'center',
                                    borderWidth: 0,
                                    // height: LINE_HEIGHT,
                                    borderColor: 'white',
                                    flexDirection: 'row'

                                    // justifyContent: 'flex-end'
                                }}>
                                {bottomIcons.map((icon, idx) => {
                                    if (idx < bottomIcons.length - 1) {
                                        return (
                                            <div
                                                key={idx}
                                                style={{
                                                    marginRight: ICON_MARGIN_LEFT
                                                }}>
                                                {icon}
                                            </div>
                                        )
                                    }
                                    return icon
                                })}
                            </View>
                        )}
                    </View>
                </View>
            </View>
        )
    }

    renderSeperator(hasTopMargin = true, showSeperator = true, hasBottomMargin = true) {
        return (
            <View
                style={{
                    alignSelf: 'center',
                    paddingHorizontal: uc.MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE,
                    marginTop: hasTopMargin ? uc.MODAL_SECTION_VERTICAL_SPACE : 0,
                    marginBottom: hasBottomMargin ? uc.MODAL_SECTION_VERTICAL_SPACE : 0,
                    width: '90%',
                    height: showSeperator ? 1 : 0,
                    backgroundColor: iOSColors.customGray
                }}
            />
        )
    }

    renderActivities() {
        return (
            <View style={{ marginBottom: 0, borderWidth: 0, width: '100%' }}>
                <span
                    style={{
                        fontWeight: '600',
                        color: iOSColors.black,
                        fontSize: this.UNIT_HEADER_FONT_SIZE + 1,
                        marginBottom: 12
                    }}>
                    Activity
                </span>

                <FlatList
                    data={this.state.activities}
                    style={{}}
                    renderItem={({ item }) => (
                        <ActivityRow
                            activity={item}
                            currentUser={this.props.currentUser}
                            currentOrganization={this.props.currentOrganization}
                        />
                    )}
                />
            </View>
        )
    }

    renderCleaningSchedule() {
        return (
            <View style={{ marginBottom: 0, borderWidth: 0, width: '100%' }}>
                <div className="d-flex mb-4">
                    <i className="specta-rules font-size-h3 line-height-xl text-dark mr-4" />
                    <span className="font-weight-bolder font-size-h2">Housekeeping plan</span>
                </div>
                <span className="text-muted font-weight-bolder font-size-h6 mb-7">Press a day to either clean or skip cleaning.</span>

                <CleaningScheduleLocal
                    allBookings={this.bookings}
                    selectedDateNumber={this.state.selectedDateNumber}
                    guestData={{
                        area: this.props.area,
                        booking: this.state.leadBooking,
                        rules: this.props.area.rules,
                        organization: this.props.currentOrganization
                    }}
                />
            </View>
        )
    }

    openInNewTab(url) {
        window.open(url, '_blank')
    }

    renderDeveloperSection() {
        const TITLE_FONT_SIZE = 13
        const CONTENT_FONT_SIZE = 14
        const TITLE_FONT_COLOR = iOSColors.gray

        const BOOKINGS_MARGIN_VERTICAL = 6

        let area = this.state.area
        let task = this.state.task
        area.tasks = []

        return (
            <View style={{ width: '100%' }}>
                <span
                    style={{
                        fontWeight: '600',
                        color: iOSColors.black,
                        fontSize: this.UNIT_HEADER_FONT_SIZE,
                        // marginBottom: 12,
                        overflow: 'visible'
                    }}>
                    Developer section
                </span>
                {/* {this.state.area && this.state.area.ruleName && ( */}
                <div className="row row-paddingless mt-5">
                    <div>
                        <strong>Calculation results:</strong>&nbsp;
                        {this.state.area.ruleName}
                    </div>
                </div>
                {/* )} */}
                <View
                    style={{
                        flexDirection: 'row',
                        backgroundColor: iOSColors.white,
                        justifyContent: 'space-between',
                        marginVertical: 12
                    }}>
                    <View>
                        <span
                            style={{
                                fontWeight: '500',
                                color: TITLE_FONT_COLOR,
                                fontSize: TITLE_FONT_SIZE,
                                marginBottom: 6
                            }}>
                            Organization key
                        </span>

                        <CopyToClipboard text={this.props.currentUser.organizationKey} />
                    </View>
                    <View>
                        <span
                            style={{
                                fontWeight: '500',
                                color: TITLE_FONT_COLOR,
                                fontSize: TITLE_FONT_SIZE,
                                marginBottom: 6
                            }}>
                            Area key
                        </span>

                        <CopyToClipboard text={area.key} />
                    </View>

                    {this.state.DEUBUG_todaysBookings && (
                        <View>
                            <span
                                style={{
                                    fontWeight: '500',
                                    color: TITLE_FONT_COLOR,
                                    fontSize: TITLE_FONT_SIZE,
                                    marginBottom: 6
                                }}>
                                Today&apos;s bookings
                            </span>
                            {this.state.DEUBUG_todaysBookings.length > 0 &&
                                this.state.DEUBUG_todaysBookings.map(booking => (
                                    <span key={booking.bookingId}>
                                        {booking.bookingId !== booking.key ? (
                                            <span>
                                                Id: <CopyToClipboard text={booking.bookingId} />
                                                Key: <CopyToClipboard text={booking.key} />
                                            </span>
                                        ) : (
                                            <CopyToClipboard text={booking.key} />
                                        )}
                                    </span>
                                ))}
                            {this.state.DEUBUG_todaysBookings.length < 1 && (
                                <span style={{ fontWeight: '500', color: iOSColors.black, fontSize: CONTENT_FONT_SIZE }}>
                                    No bookings found
                                </span>
                            )}
                        </View>
                    )}
                    {this.state.DEUBUG_futureBookings && (
                        <View>
                            <span
                                style={{
                                    fontWeight: '500',
                                    color: TITLE_FONT_COLOR,
                                    fontSize: TITLE_FONT_SIZE,
                                    marginBottom: 6
                                }}>
                                Future booking
                            </span>
                            {this.state.DEUBUG_futureBookings.length > 0 &&
                                (this.state.DEUBUG_futureBookings[0].bookingId !== this.state.DEUBUG_futureBookings[0].key ? (
                                    <span>
                                        Id: <CopyToClipboard text={this.state.DEUBUG_futureBookings[0].bookingId} />
                                        Key: <CopyToClipboard text={this.state.DEUBUG_futureBookings[0].key} />
                                    </span>
                                ) : (
                                    <CopyToClipboard text={this.state.DEUBUG_futureBookings[0].key} />
                                ))}
                            {this.state.DEUBUG_futureBookings.length < 1 && (
                                <span style={{ fontWeight: '500', color: iOSColors.black, fontSize: CONTENT_FONT_SIZE }}>
                                    No bookings found
                                </span>
                            )}
                        </View>
                    )}
                </View>
                <span style={{ fontWeight: '500', color: TITLE_FONT_COLOR, fontSize: TITLE_FONT_SIZE, marginBottom: 6 }}>Area JSON</span>
                <input className="form-control" type="text" value={JSON.stringify(area)} readOnly />
                <span style={{ fontWeight: '500', color: TITLE_FONT_COLOR, fontSize: TITLE_FONT_SIZE, marginBottom: 6 }}>Task JSON</span>
                <input className="form-control" type="text" value={JSON.stringify(task)} readOnly />
            </View>
        )
    }
}
async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text)
    } else {
        return document.execCommand('copy', true, text)
    }
}
const CopyToClipboard = ({ text }) => {
    return (
        <span>
            {text}
            <a
                onClick={async () => {
                    await copyTextToClipboard(text)
                }}>
                ✂️
            </a>
        </span>
    )
}
export default withRouter(HousekeepingModal)
